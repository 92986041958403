import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format'

export default function DateCellRenderer(row) {
  try {
    const { cellData, dataKey, rowData: { sortColumnName } } = row;
    const cellClassName = (dataKey === sortColumnName) ? 'SortingColumnCell' : null;
    if (cellData && cellData !== '2099-01-01T00:00:00.000Z') {
      return (
          <div className={cellClassName}>
            {format(new Date(cellData), 'yyyy-MM-dd')}
          </div>
      );
    }

    return <div />;
  } catch {
    return <div>...</div>;
  }

}

DateCellRenderer.propTypes = {
  row: PropTypes.shape({
    cellData: PropTypes.string,
    dataKey: PropTypes.string.isRequired,
    rowData: PropTypes.shape({
      sortColumnName: PropTypes.string
    })
  })
};
