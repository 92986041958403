import React from 'react';
import PropTypes from 'prop-types';
import { Input, Select } from 'antd';
import _ from 'lodash';
import './OrderingComponent.css'

const InputGroup = Input.Group;
const Option = Select.Option;
const dropdownStyle = {
  minWidth: 250
};

const getOrderByColumnsList = (orderByColumnsList, removeColumnString) => {
  let copyOfOrderByColumnsList = [];

  if (removeColumnString) {
    const removeColumn = _.split(removeColumnString, '-')[1];
    copyOfOrderByColumnsList = orderByColumnsList.reduce((result, item) => {
      const column = _.split(item.value, '-')[1];

      if (removeColumn !== column) {
        result.push(item);
      }

      return result;
    }, []);
  } else {
    copyOfOrderByColumnsList = orderByColumnsList.slice(0);
  }
  return copyOfOrderByColumnsList;
};

const renderDropdownItems = (orderByColumnsList, removeColumnString) => (
  getOrderByColumnsList(orderByColumnsList, removeColumnString).map((orderByColumns) => (
    <Option key={orderByColumns.key} value={orderByColumns.value}>
      <div dangerouslySetInnerHTML={{__html: orderByColumns.text}}/>
    </Option>
  )));

export default function OrderingComponent({handleOrderByColumn1, handleOrderByColumn2, orderByColumns, orderByColumnsList}) {
  // Second order by column hidden from users to simplify UI:
  // <Select defaultValue={orderByColumns[1]} value={orderByColumns[1]} onChange={handleOrderByColumn2} style={dropdownStyle} id="1">
  //   {renderDropdownItems(orderByColumnsList, orderByColumns[0])}
  // </Select>
  return (
    <div className="OrderingComponent">
      <div className="OrderMainTitle">
        Order by:
      </div>
      <div className="OrderDropdowns">
        <InputGroup compact>
          <Select data-testid="order-by-dropdown" defaultValue={orderByColumns[0]} value={orderByColumns[0]} onChange={handleOrderByColumn1} style={dropdownStyle} id="0">
            {renderDropdownItems(orderByColumnsList)}
          </Select>
        </InputGroup>
      </div>
    </div>
  );
}

OrderingComponent.propTypes = {
  handleOrderByColumn1: PropTypes.func.isRequired,
  handleOrderByColumn2: PropTypes.func.isRequired,
  orderByColumns: PropTypes.array.isRequired,
  orderByColumnsList: PropTypes.array.isRequired
};
