import React from 'react';
import './App.css';
import 'antd/dist/antd.css';
import MainView from 'components/MainView'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { isMobile } from "react-device-detect";

const appStyle = isMobile ? {
  width: '100%',
  height: '100%',
  margin: 0,
  padding: 0
} : {
  minWidth: 960,
  width: '100%',
  height: '100%',
  margin: 0,
  padding: 0
};

export default function App() {
  return (
    <Router>
      <div className="App" style={appStyle}>
        <Switch>
          <Route path="/" exact component={MainView} />
          <Route path="/sort/market-cap/:direction(asc|desc)" render={props => <MainView initialOrderByColumn1="market_cap" {...props} />} />
          <Route path="/sort/times-to-get-back-to-ico-price/:direction(asc|desc)" render={props => <MainView initialOrderByColumn1="times_to_get_back_to_ico_price" {...props} />} />
          <Route path="/sort/future-inflation/:direction(asc|desc)" render={props => <MainView initialOrderByColumn1="future_inflation" {...props} />} />
          <Route path="/sort/raised-usd/:direction(asc|desc)" render={props => <MainView initialOrderByColumn1="raised_usd" {...props} />} />
          <Route path="/sort/beta-coefficient/:direction(asc|desc)" render={props => <MainView initialOrderByColumn1="beta_value" {...props} />} />
          <Route path="/sort/volume-24hours/:direction(asc|desc)" render={props => <MainView initialOrderByColumn1="volume_24h" {...props} />} />
          <Route path="/sort/percent-change-7days/:direction(asc|desc)" render={props => <MainView initialOrderByColumn1="percent_change_7d" {...props} />} />
          <Route path="/sort/percent-change-24hours/:direction(asc|desc)" render={props => <MainView initialOrderByColumn1="percent_change_24h" {...props} />} />
          <Route path="/sort/percent-change-since-ico/:direction(asc|desc)" render={props => <MainView initialOrderByColumn1="pct_since_ico" {...props} />} />
          <Route path="/sort/percent-change-from-all-time-high-price/:direction(asc|desc)" render={props => <MainView initialOrderByColumn1="percent_from_price_ath" {...props} />} />
          <Route path="/sort/all-time-high-date/:direction(asc|desc)" render={props => <MainView initialOrderByColumn1="ath_date" {...props} />} />
          <Route path="/sort/ico-date/:direction(asc|desc)" render={props => <MainView initialOrderByColumn1="ico_date" {...props} />} />
          <Route path="/android/" exact render={() => <MainView android/>} />
          <Route path="/all/" exact render={() => <MainView showAllCryptos/>} />
          <Route path="/all/sort/market-cap/:direction(asc|desc)" render={props => <MainView showAllCryptos initialOrderByColumn1="market_cap" {...props} />} />
          <Route path="/all/sort/future-inflation/:direction(asc|desc)" render={props => <MainView showAllCryptos initialOrderByColumn1="future_inflation" {...props} />} />
          <Route path="/all/sort/beta-coefficient/:direction(asc|desc)" render={props => <MainView showAllCryptos initialOrderByColumn1="beta_value" {...props} />} />
          <Route path="/all/sort/volume-24hours/:direction(asc|desc)" render={props => <MainView showAllCryptos initialOrderByColumn1="volume_24h" {...props} />} />
          <Route path="/all/sort/percent-change-7days/:direction(asc|desc)" render={props => <MainView showAllCryptos initialOrderByColumn1="percent_change_7d" {...props} />} />
          <Route path="/all/sort/percent-change-24hours/:direction(asc|desc)" render={props => <MainView showAllCryptos initialOrderByColumn1="percent_change_24h" {...props} />} />
          <Route path="/all/sort/percent-change-from-all-time-high-price/:direction(asc|desc)" render={props => <MainView showAllCryptos initialOrderByColumn1="percent_from_price_ath" {...props} />} />
          <Route path="/all/sort/all-time-high-date/:direction(asc|desc)" render={props => <MainView showAllCryptos initialOrderByColumn1="ath_date" {...props} />} />
          <Route path="/search/:keyword" component={MainView} />
          <Route path="/fl/:showAllCryptos/:orderingKey/:cmcRankFilterRange/:inflationFilterRange/:marketCapFilterRange/:betaFilterRange/:volumeFilterRange/:change7dFilterRange/:icoRaisedFilterRange/:changeFromIcoFilterRange/:change24hFilterRange/:keywordFL/:selectedRows/:showOnlySelected/:tagsFunctionalFilter/:tagsTechnicalFilter" component={MainView} />
          <Route path="/fl/:showAllCryptos/:orderingKey/:cmcRankFilterRange/:inflationFilterRange/:marketCapFilterRange/:betaFilterRange/:volumeFilterRange/:change7dFilterRange/:icoRaisedFilterRange/:changeFromIcoFilterRange/:change24hFilterRange/:keywordFL/:selectedRows/:showOnlySelected/" component={MainView} />
          <Route component={MainView} />
        </Switch>
      </div>
    </Router>
  );
}
