import React, { Component } from 'react';
import './HireDev.css';
import AWS from 'aws-sdk';
import ReactGA from 'react-ga';
import {
  Checkbox, Modal, Form, Input, Tooltip, Button, Row, Col
} from 'antd';
import { QuestionCircleOutlined, MessageOutlined } from '@ant-design/icons';
import { snsEndpoint, snsHireDevArn } from 'constants/config'

const FormItem = Form.Item;
const { TextArea } = Input;

class HireDev extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      confirmDirty: false,
      confirmLoading: false
    };
  };

  showModal = () => {
    ReactGA.pageview('/hire-open');
    this.setState({
      visible: true,
    });
  };

  handleFinish = values => {
    const valuesString = JSON.stringify(values, null, 2);

    this.setState({
      confirmLoading: true
    });

    AWS.config.update({
      endpoint: snsEndpoint
    });
    const sns = new AWS.SNS({params: {TopicArn: snsHireDevArn}});
    sns.publish({Message: valuesString}, (err, data) => {
      setTimeout(() => {
        if (!err) {
          ReactGA.pageview('/hire-send-success');
          //KT TODO: refactor to functional coponent
          // const [form] = Form.useForm();
          // form.resetFields();
        }
        this.setState({
          visible: false,
          confirmLoading: false
        });
      }, 1000);
    });
  }

  handleCancel = () => {
    ReactGA.pageview('/hire-cancel');
    this.setState({
      visible: false,
    });
  };

  render() {
    const { visible, confirmLoading } = this.state;

    const formItemLayout = {
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 18
      }
    };

    const checkboxItemLayout = {
      wrapperCol: {
        offset: 2,
        span: 22
      }
    };

    return (
      <div>
        <div className="NeedDevButtonContainer">
          <Button type="secondary" size="small" icon={<MessageOutlined />} block={true} onClick={this.showModal}>
            Need developer?
          </Button>
        </div>
        <Modal
          title="Need experienced React and Node.js dev for your project?"
          visible={visible}
          onCancel={this.handleCancel}
          footer={null}
        >
          <div className="TopMsg">
            Get full stack JS development service from developer of this tool.
          </div>

          <Form onFinish={this.handleFinish} initialValues={{ checkboxesSelected: [] }}>

            <FormItem
              {...formItemLayout}
              label="Name"
              name="name"
              rules={[{required: true, message: 'Please enter your name', whitespace: true}]}
            >
              <Input placeholder="Your name" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Company"
              name="company"
              rules={[{required: false}]}
            >
              <Input placeholder="Company" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="E-mail"
              name="email"
              rules={[{
                type: 'email', message: 'This is not a valid email yet',
              }, {
                required: true, message: 'Please enter your email',
              }]}
            >
              <Input placeholder="Your email" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label={(
                <span>
              Time &nbsp;
                  <Tooltip title="Approximate contract length">
                  <QuestionCircleOutlined />
              </Tooltip>
            </span>
              )}
              name="length"
              rules={[{required: true, message: 'Please enter approximate contract length', whitespace: true}]}
            >
              <Input placeholder="How long do you need my services?" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label={(
                <span>
              Rate ($/h) &nbsp;
                  <Tooltip title="How much can you afford to pay?">
                  <QuestionCircleOutlined />
              </Tooltip>
            </span>
              )}
              name="rate"
              rules={[{ required: true, message: 'Please enter maximum rate you can pay', whitespace: true }]}
            >
              <Input placeholder="How much can you afford to pay?" />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Description"
              name="description"
              rules={[{required: true, message: 'Please enter something about your offer', whitespace: true}]}
            >
              <TextArea rows={6} placeholder="Job / project description" />
            </FormItem>

            <FormItem
                {...checkboxItemLayout}
                name="checkboxesSelected"
            >
              <Checkbox.Group style={{ width: "100%" }}>
                <Row>
                  <Col span={14}><Checkbox value="Prefer to pay in crypto">Prefer to pay in cryptocurrency</Checkbox></Col>
                  <Col span={8}><Checkbox value="Need full time">Need full time</Checkbox></Col>
                </Row>
              </Checkbox.Group>
            </FormItem>

            <FormItem>
              <Button className="FooterButton" loading={confirmLoading} type="primary" htmlType="submit">Send</Button>
              <Button className="FooterButton" key="cancel" onClick={this.handleCancel} type="secondary">
                Cancel
              </Button>
            </FormItem>

          </Form>
        </Modal>
      </div>
    );
  }
}

export default HireDev;
