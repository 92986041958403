import {
  split,
  toNumber,
  isEmpty,
  includes,
  find,
} from 'lodash';

export const getRangeParamValuesFromParams = (params, paramName) => {
  if (params[paramName] && params[paramName] !== 'f') {
    const range = split(params[paramName], ',').map(value => toNumber(value));

    if (range.length === 2 && !isNaN(range[0]) && !isNaN(range[1])) {
      return range;
    }

    return [];
  }

  return [];
};

export const doesRowPassRangeFilter = (row, fieldName, filterRange, filterRangeAdjusted) => {
  if (isEmpty(filterRange)) {
    return true;
  }

  if (filterRangeAdjusted[0] === filterRange[0] && filterRangeAdjusted[1] === filterRange[1]) {
    return true;
  }

  if (filterRangeAdjusted[0] !== filterRange[0] && filterRangeAdjusted[1] !== filterRange[1] &&
    row[fieldName] >= filterRange[0] && row[fieldName] <= filterRange[1]) {
    return true;
  }

  if (filterRangeAdjusted[0] === filterRange[0] && filterRangeAdjusted[1] !== filterRange[1] &&
    row[fieldName] <= filterRange[1]) {
    return true;
  }

  return !!(filterRangeAdjusted[0] !== filterRange[0] && filterRangeAdjusted[1] === filterRange[1] &&
  row[fieldName] >= filterRange[0]);
};

export const doesRowPassTagsFilter = (row, tagsData, tagsFilter) => {
  if (!tagsFilter) {
    return true;
  }

  return includes(find(tagsData, {'id': tagsFilter}).coins, row.cp_id) ||
    includes(find(tagsData, {'id': tagsFilter}).icos, row.cp_id);
};

