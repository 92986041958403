import React from 'react';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon
} from 'react-share';
import ReactGA from 'react-ga';
import { baseUrl } from 'constants/config';
import './SharingRow.css';

const title = 'Find Crypto Gem';
const shortDescription = 'Compare all cryptocurrencies current prices to ICO prices';
const longDescription = 'Compare all cryptocurrencies current prices to ICO prices in one table. Find most undervalued ones.';
const hashtag = '#crypto';
const hashtags = ['crypto', 'cryptocurrency', 'ICO'];

export default function SharingRow({iconSize, padding, mobileView, fullLink}) {
  let url;

  if (fullLink) {
    url = fullLink;
  } else {
    url = baseUrl;
  }

  const shareClickGA = (type, mobileView) => {
    if (mobileView) {
      ReactGA.pageview(`/mobile-view/share-${type}`);
    } else {
      ReactGA.pageview(`/share-${type}`);
    }
  };
  const shareCloseGA = (type, mobileView) => {
    if (mobileView) {
      ReactGA.pageview(`/mobile-view/share-${type}-close`);
    } else {
      ReactGA.pageview(`/share-${type}-close`);
    }
  };

  return (
    <div className="ShareButtonsRow">
      <div style={{padding}}>
        <FacebookShareButton
          url={url}
          beforeOnClick={() =>{shareClickGA('facebook', mobileView)}}
          onShareWindowClose={() =>{shareCloseGA('facebook', mobileView)}}
          quote={longDescription}
          hashtag={hashtag}
        >
          <FacebookIcon size={iconSize} round />
        </FacebookShareButton>
      </div>
      <div style={{padding}}>
        <TwitterShareButton
          url={url}
          beforeOnClick={() =>{shareClickGA('twitter', mobileView)}}
          onShareWindowClose={() =>{shareCloseGA('twitter', mobileView)}}
          title={shortDescription}
          hashtags={hashtags}
        >
          <TwitterIcon size={iconSize} round />
        </TwitterShareButton>
      </div>
      <div style={{padding}}>
        <TelegramShareButton
          url={url}
          beforeOnClick={() =>{shareClickGA('telegram', mobileView)}}
          onShareWindowClose={() =>{shareCloseGA('telegram', mobileView)}}
          title={longDescription}
        >
          <TelegramIcon size={iconSize} round />
        </TelegramShareButton>
      </div>
      <div style={{padding}}>
        <WhatsappShareButton
          url={url}
          beforeOnClick={() =>{shareClickGA('whatsapp', mobileView)}}
          onShareWindowClose={() =>{shareCloseGA('whatsapp', mobileView)}}
          title={longDescription}
        >
          <WhatsappIcon size={iconSize} round />
        </WhatsappShareButton>
      </div>
      <div style={{padding}}>
        <EmailShareButton
          url={url}
          subject={title}
          body={longDescription + ' ' + url}
          beforeOnClick={() =>{shareClickGA('email', mobileView)}}
          onShareWindowClose={() =>{shareCloseGA('email', mobileView)}}
        >
          <EmailIcon size={iconSize} round />
        </EmailShareButton>
      </div>
    </div>
  );
}

SharingRow.propTypes = {
  iconSize: PropTypes.number.isRequired,
  padding: PropTypes.string.isRequired,
  mobileView: PropTypes.bool,
  fullLink: PropTypes.string
};
