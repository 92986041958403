import React, { Fragment } from 'react';
import {
  BrowserView,
  MobileView
} from 'react-device-detect';
import logo from 'assets/gem.png';
import './Logo.css';

export default function Logo() {
  return (
    <Fragment>
      <BrowserView>
        <div className="LogoContainer">
          <img src={logo} className="LogoImage" alt="Gem"/>
          <div className="LogoTitle">
            FindCryptoGem.com
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className="LogoContainerMobile">
          <img src={logo} className="LogoImage" alt="Gem"/>
          <div className="LogoTitleMobile">
            <div className="LogoTitleRowMobile">
              Find
            </div>
            <div className="LogoTitleRowMobile">
              Crypto
            </div>
            <div className="LogoTitleRowMobile">
              Gem
            </div>
          </div>
        </div>
      </MobileView>
    </Fragment>
  );
}
