import React, { PureComponent } from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import _ from 'lodash';
import AWS from 'aws-sdk';
import fuzzysearch from 'fuzzysearch';
import formatDistance from 'date-fns/formatDistance'
import ReactGA from 'react-ga';
import axios from 'axios';
import {
  BrowserView,
  MobileView,
  isMobile
} from 'react-device-detect';
import 'sticky-react-table/lib/themes/light.scss';
import { CryptoTable, CryptoTableMobile } from 'components/CryptoTable';
import { awsRegion, dynamoDBEndpoint, baseUrl, tagsUrl, pageSize, gaTrackingCode } from 'constants/config';
import OrderingComponent from 'components/OrderingComponent';
import FilteringComponent from 'components/FilteringComponent';
import Logo from 'components/Logo';
import SearchBox from 'components/SearchBox';
import Spinner from 'components/Spinner';
import Donate from 'components/Donate';
import Disclaimer from 'components/Disclaimer';
import Feedback from 'components/Feedback';
import FullLink from 'components/FullLink';
import HireDev from 'components/HireDev';
import SharingRow from 'components/SharingRow';
import NoDataFound from 'components/NoDataFound';
import icoData from 'data/cryptos.json';
import {
  getRangeParamValuesFromParams,
  doesRowPassRangeFilter,
  doesRowPassTagsFilter,
}  from 'utils/filters';
import './MainView.css';
import ORDER_BY_COLUMNS_LIST from 'constants/order-by-columns-list';

let ab_variant = 0; //_.random(1, 2);
let timesColumnLessPriority; // = (ab_variant === 2);
//  const initialOrderByColumn1 = (ab_variant === 1) ? 'desc-times_to_get_back_to_ico_price' : 'desc-market_cap';
const searchDebounceWaitMilisec = isMobile ? 800 : 500;
const decreaseFiltersRangesBy = 15;
let marketCapMinAdjusted = 0;
let marketCapMaxAdjusted = 0;
let cmcRankMin = 0;
let cmcRankMax = 0;
let inflationMinAdjusted = 0;
let inflationMaxAdjusted = 0;
let betaMinAdjusted = 0;
let betaMaxAdjusted = 0;
let volumeMinAdjusted = 0;
let volumeMaxAdjusted = 0;
let change7dMinAdjusted = 0;
let change7dMaxAdjusted = 0;
let icoRaisedMinAdjusted = 0;
let icoRaisedMaxAdjusted = 0;
let changeFromIcoMinAdjusted = 0;
let changeFromIcoMaxAdjusted = 0;
let change24hMinAdjusted = 0;
let change24hMaxAdjusted = 0;

class MainView extends PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    timesColumnLessPriority = true; //isMobile ? true : (ab_variant === 2);
    let orderByColumn1 = 'asc-cmc_rank'; //isMobile ? 'desc-market_cap' : initialOrderByColumn1;
    let showAllCryptos = true; //isMobile;
    let tagsFunctionalFilterMainView = null;
    let tagsTechnicalFilterMainView = null;
    let cmcRankFilterRangeMainView = [];
    let inflationFilterRangeMainView = [];
    let marketCapFilterRangeMainView = [];
    let betaFilterRangeMainView = [];
    let volumeFilterRangeMainView = [];
    let change7dFilterRangeMainView = [];
    let icoRaisedFilterRangeMainView = [];
    let changeFromIcoFilterRangeMainView = [];
    let change24hFilterRangeMainView = [];
    let keyword = null;
    let selectedRows = [];
    let showOnlySelectedMainView = false;

    // all route:
    if (props.showAllCryptos) {
      showAllCryptos = props.showAllCryptos;
      orderByColumn1 = 'asc-cmc_rank';
      timesColumnLessPriority = true;
      ab_variant = 0;
    }

    if (props.android) {
      showAllCryptos = props.android;
      orderByColumn1 = 'asc-cmc_rank';
      timesColumnLessPriority = true;
      ab_variant = 0;
    }

    if (props.match && props.match.params) {
      const { params } = props.match;

      // sort route:
      if (params.direction && props.initialOrderByColumn1) {
        orderByColumn1 = `${params.direction}-${props.initialOrderByColumn1}`;
        ab_variant = 0;
      }

      // search route:
      if (params.keyword) {
        showAllCryptos = true;
        keyword = params.keyword;
        orderByColumn1 = 'desc-market_cap';
        timesColumnLessPriority = true;
        ab_variant = 0;
      }

      // fl (full link) route:
      if (params.showAllCryptos) {
        showAllCryptos = params.showAllCryptos !== 'f';
      }
      if (params.orderingKey) {
        orderByColumn1 = ORDER_BY_COLUMNS_LIST[params.orderingKey].value;
      }
      cmcRankFilterRangeMainView = getRangeParamValuesFromParams(params, 'cmcRankFilterRange');
      inflationFilterRangeMainView = getRangeParamValuesFromParams(params, 'inflationFilterRange');
      marketCapFilterRangeMainView = getRangeParamValuesFromParams(params, 'marketCapFilterRange');
      betaFilterRangeMainView = getRangeParamValuesFromParams(params, 'betaFilterRange');
      volumeFilterRangeMainView = getRangeParamValuesFromParams(params, 'volumeFilterRange');
      change7dFilterRangeMainView = getRangeParamValuesFromParams(params, 'change7dFilterRange');
      icoRaisedFilterRangeMainView = getRangeParamValuesFromParams(params, 'icoRaisedFilterRange');
      changeFromIcoFilterRangeMainView = getRangeParamValuesFromParams(params, 'changeFromIcoFilterRange');
      change24hFilterRangeMainView = getRangeParamValuesFromParams(params, 'change24hFilterRange');

      if (params.keywordFL && params.keywordFL !== 'f') {
        keyword = params.keywordFL;
      }
      if (params.selectedRows && params.selectedRows !== 'f') {
        selectedRows = _.split(params.selectedRows, ',').map(value => _.toNumber(value));
      }
      if (params.showOnlySelected && params.showOnlySelected === 't') {
        showOnlySelectedMainView = true;
      }
      if (params.tagsFunctionalFilter && params.tagsFunctionalFilter !== 'f') {
        tagsFunctionalFilterMainView = params.tagsFunctionalFilter;
      }
      if (params.tagsTechnicalFilter && params.tagsTechnicalFilter !== 'f') {
        tagsTechnicalFilterMainView = params.tagsTechnicalFilter;
      }
    }
    const filtersActive = (
      !(
        !tagsFunctionalFilterMainView &&
        !tagsTechnicalFilterMainView &&
        _.isEmpty(cmcRankFilterRangeMainView) &&
        _.isEmpty(inflationFilterRangeMainView) &&
        _.isEmpty(marketCapFilterRangeMainView) &&
        _.isEmpty(betaFilterRangeMainView) &&
        _.isEmpty(volumeFilterRangeMainView) &&
        _.isEmpty(change7dFilterRangeMainView) &&
        _.isEmpty(icoRaisedFilterRangeMainView) &&
        _.isEmpty(changeFromIcoFilterRangeMainView) &&
        _.isEmpty(change24hFilterRangeMainView) &&
        !showOnlySelectedMainView &&
        !keyword
      )
    );

    this.state = {
      allRows: [],
      loadedRowsCount: pageSize,
      dataLoaded: false,
      filtersActive,
      filtersVisible: false,
      filteredRows: [],
      keyword: keyword,
      cmcData: null,
      rows: [],
      orderByColumns: [orderByColumn1, 'desc-volume_24h'],
      quotesUpdatedTimeAgo: '',
      tagsFunctionalFilterMainView,
      tagsTechnicalFilterMainView,
      cmcRankFilterRangeMainView,
      inflationFilterRangeMainView,
      marketCapFilterRangeMainView,
      betaFilterRangeMainView,
      volumeFilterRangeMainView,
      change7dFilterRangeMainView,
      icoRaisedFilterRangeMainView,
      changeFromIcoFilterRangeMainView,
      change24hFilterRangeMainView,
      showAllCryptos,
      selectedRows,
      showOnlySelectedMainView,
      tagsData: [],
      tagsFunctional: [],
      tagsTechnical: []
    };
  }

  componentDidMount() {
    this.getCMCData();
    ReactGA.initialize(gaTrackingCode, {
      testMode: process.env.NODE_ENV === 'test',
      gaOptions: {
        ab_variant
      }
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  setFiltersMinMaxValues = (processedCryptos) => {
    const sortedMarketCapArray = _.pullAll(_.orderBy(_.map(processedCryptos, 'market_cap')), [0, NaN, undefined, null]);
    marketCapMinAdjusted = sortedMarketCapArray[decreaseFiltersRangesBy-1];
    marketCapMaxAdjusted = sortedMarketCapArray[sortedMarketCapArray.length-decreaseFiltersRangesBy];

    const sortedCmcRankArray = _.pullAll(_.orderBy(_.map(processedCryptos, 'cmc_rank')), [NaN, undefined, null]);
    cmcRankMin = sortedCmcRankArray[0];
    cmcRankMax = sortedCmcRankArray[sortedCmcRankArray.length-1];

    const sortedInflationArray = _.pullAll(_.orderBy(_.map(processedCryptos, 'future_inflation')), [NaN, undefined, null]);
    inflationMinAdjusted = Math.floor(sortedInflationArray[decreaseFiltersRangesBy-1]);
    inflationMaxAdjusted = Math.ceil(sortedInflationArray[sortedInflationArray.length-decreaseFiltersRangesBy]);

    const sortedBetaArray = _.pullAll(_.orderBy(_.map(processedCryptos, 'beta_value')), [NaN, undefined, null]);
    betaMinAdjusted = Math.floor(sortedBetaArray[decreaseFiltersRangesBy-1]);
    betaMaxAdjusted = Math.ceil(sortedBetaArray[sortedBetaArray.length-decreaseFiltersRangesBy]);

    const sortedVolumeArray = _.pullAll(_.orderBy(_.map(processedCryptos, 'volume_24h')), [0, NaN, undefined, null]);
    volumeMinAdjusted = Math.floor(sortedVolumeArray[decreaseFiltersRangesBy-1]);
    volumeMaxAdjusted = Math.ceil(sortedVolumeArray[sortedVolumeArray.length-decreaseFiltersRangesBy]);

    const sortedChange7dArray = _.orderBy(_.map(processedCryptos, 'percent_change_7d'));
    change7dMinAdjusted = Math.floor(sortedChange7dArray[decreaseFiltersRangesBy-1]);
    change7dMaxAdjusted = Math.ceil(sortedChange7dArray[sortedChange7dArray.length-decreaseFiltersRangesBy]);

    const sortedIcoRaisedArray = _.pullAll(_.orderBy(_.map(processedCryptos, 'raised_usd')), [0, NaN, undefined, null]);
    icoRaisedMinAdjusted = Math.floor(sortedIcoRaisedArray[decreaseFiltersRangesBy-1]);
    icoRaisedMaxAdjusted = Math.ceil(sortedIcoRaisedArray[sortedIcoRaisedArray.length-decreaseFiltersRangesBy]);

    const sortedChangeFromIcoArray = _.pullAll(_.orderBy(_.map(processedCryptos, 'pct_since_ico')), [-100, NaN, undefined, null]);
    changeFromIcoMinAdjusted = Math.ceil(sortedChangeFromIcoArray[decreaseFiltersRangesBy-1]);
    changeFromIcoMaxAdjusted = Math.ceil(sortedChangeFromIcoArray[sortedChangeFromIcoArray.length-decreaseFiltersRangesBy]);

    const sortedChange24hArray = _.orderBy(_.map(processedCryptos, 'percent_change_24h'));
    change24hMinAdjusted = Math.floor(sortedChange24hArray[decreaseFiltersRangesBy-1]);
    change24hMaxAdjusted = Math.ceil(sortedChange24hArray[sortedChange24hArray.length-decreaseFiltersRangesBy]);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.showOnlySelectedMainView && _.isEmpty(this.state.selectedRows) && !_.isEmpty(prevState.selectedRows)) {
      this.setState({showOnlySelectedMainView: false}, () => {
        this.filterRows();
      });
    }
  };

  getMarketAverages = (cmcData) => {
    let cryptosCount = 0;
    let globalMarketCap = 0;

    const multipliedSums = cmcData.reduce((result, crypto) => {
      if (
        crypto && crypto.quote && crypto.quote.USD &&
        crypto.quote.USD.market_cap &&
        crypto.quote.USD.volume_24h &&
        crypto.quote.USD.percent_change_7d &&
        crypto.quote.USD.percent_change_24h
      ) {
        cryptosCount++;
        globalMarketCap = globalMarketCap + crypto.quote.USD.market_cap;
        const change7dMultiplyMarketCap = crypto.quote.USD.percent_change_7d * crypto.quote.USD.market_cap;
        const change24hMultiplyMarketCap = crypto.quote.USD.percent_change_24h * crypto.quote.USD.market_cap;
        const change7dMultiplyVolume = crypto.quote.USD.percent_change_7d * crypto.quote.USD.volume_24h;
        const change24hMultiplyVolume = crypto.quote.USD.percent_change_24h * crypto.quote.USD.volume_24h;
        return {
          change7dMultiplyMarketCapSum: result.change7dMultiplyMarketCapSum + change7dMultiplyMarketCap,
          change24hMultiplyMarketCapSum: result.change24hMultiplyMarketCapSum + change24hMultiplyMarketCap,
          change7dMultiplyVolumeSum: result.change7dMultiplyVolumeSum + change7dMultiplyVolume,
          change24hMultiplyVolumeSum: result.change24hMultiplyVolumeSum + change24hMultiplyVolume
        }
      }
      return result;
    }, {
      change7dMultiplyMarketCapSum: 0,
      change24hMultiplyMarketCapSum: 0,
      change7dMultiplyVolumeSum: 0,
      change24hMultiplyVolumeSum: 0
    });
    const averageMarketCap = globalMarketCap / cryptosCount;

    // change7dMarketAvgCalculatedByVolume - may be not accurate, because using 24h volume for 7 day calculation
    // change24hMarketAvgCalculatedByVolume - may be not accurate, because stable coins have huge volume and may mess up calculation
    return {
      change7dMarketAvgCalculatedByMarketCap: _.round(multipliedSums.change7dMultiplyMarketCapSum/cryptosCount/averageMarketCap, 2),
      change24hMarketAvgCalculatedByMarketCap: _.round(multipliedSums.change24hMultiplyMarketCapSum/cryptosCount/averageMarketCap, 2),
      change7dMarketAvgCalculatedByVolume: _.round(multipliedSums.change7dMultiplyVolumeSum/cryptosCount/averageMarketCap, 2),
      change24hMarketAvgCalculatedByVolume: _.round(multipliedSums.change24hMultiplyVolumeSum/cryptosCount/averageMarketCap, 2)
    };
  };

  processCMCData = cmcData => {
    const { showAllCryptos, keyword, filtersActive } = this.state;

    const { cryptos } = icoData;
    // in case we do not get data from dynamodb:
    if (!cmcData) {
      cmcData = cryptos;
      this.setState({cmcData});
    }

    const marketAverages = this.getMarketAverages(cmcData);

    let quotesUpdatedTimeAgo;
    const processedCryptos = cmcData.reduce((result, cmcDataItem) => {
      const crypto = _.find(cryptos, crypto => (cmcDataItem.id === crypto.id));

      if ((crypto && crypto.ico_price && !crypto.hide) || showAllCryptos) {
        const newDataItem = { ...cmcDataItem };
        if (newDataItem) {
          if (crypto) {
            newDataItem.raised_usd = crypto.raised_usd;
            newDataItem.ico_date = crypto.ico_date;
            newDataItem.ico_price = crypto.ico_price;
            newDataItem.url_website = `${crypto.url_website}?utm_source=findcryptogem.com`;
            newDataItem.url_chat = crypto.url_chat;
            newDataItem.platform = crypto.platform;

            if (crypto.ico_received_percent !== 0) {
              newDataItem.ico_received_percent = crypto.ico_received_percent;
            }

            if (!newDataItem.raised_usd && !!crypto.ico_received) {
              newDataItem.raised_usd = parseInt(crypto.ico_received);
            }

            if ((!newDataItem.ico_date || newDataItem.ico_date === '2099-01-01T00:00:00.000Z') && !!crypto.ico_end_of_sale) {
              newDataItem.ico_date = crypto.ico_end_of_sale;
            }
          }

          newDataItem.sort_idx = result.length + 1;
          newDataItem.icon = `https://s2.coinmarketcap.com/static/img/coins/16x16/${newDataItem.id}.png`;
          newDataItem.cmc_link = `https://coinmarketcap.com/currencies/${newDataItem.slug}`;
          if (newDataItem.cp_id){
            newDataItem.cp_link = `https://coinpaprika.com/coin/${newDataItem.cp_id}`;
          } else {
            const cpId = `${_.toLower(newDataItem.symbol)}-${_.toLower(newDataItem.name.trim().replace(/\s+/g, '-').replace(/[[\]']+/g, ''))}`;
            newDataItem.cp_link = `https://coinpaprika.com/coin/${cpId}`;
          }
          newDataItem.market_cap = Math.round(newDataItem.quote.USD.market_cap);
          newDataItem.times_to_get_back_to_ico_price = _.round(Number.parseFloat(newDataItem.ico_price / newDataItem.quote.USD.price), 2);
          // Different calculation, probably worse:
          // newDataItem.pct_since_ico = Math.round(newDataItem.quote.USD.price / newDataItem.ico_price * 1000) / 10;
          newDataItem.pct_since_ico = newDataItem.ico_price ?
              _.round(((newDataItem.quote.USD.price - newDataItem.ico_price) * 100 / newDataItem.ico_price), 2) : null;
          const future_inflation = newDataItem.circulating_supply ? newDataItem.total_supply / newDataItem.circulating_supply : null;

          if (future_inflation !== 1) {
            newDataItem.future_inflation = future_inflation ? _.round(Number.parseFloat(future_inflation), 2) : null;
          }

          if (!newDataItem.percent_from_price_ath) {
            newDataItem.percent_from_price_ath = null;
          }

          newDataItem.volume_24h = newDataItem.quote.USD.volume_24h;
          newDataItem.percent_change_7d = _.round(newDataItem.quote.USD.percent_change_7d, 2);
          newDataItem.percent_change_24h = _.round(newDataItem.quote.USD.percent_change_24h, 2);
          newDataItem.volume_per_marketcap_pct = newDataItem.market_cap && newDataItem.volume_24h ?
              _.round(newDataItem.volume_24h / newDataItem.market_cap * 100, 2) : null;
          newDataItem.percent_change_7d_adjusted = newDataItem.percent_change_7d - marketAverages.change7dMarketAvgCalculatedByMarketCap;
          newDataItem.percent_change_24h_adjusted = newDataItem.percent_change_24h - marketAverages.change24hMarketAvgCalculatedByMarketCap;

          if (!newDataItem.market_cap) {
            newDataItem.market_cap_approximate = _.round(newDataItem.quote.USD.price * newDataItem.total_supply);
          }

          if (newDataItem.symbol === 'ETH') {
            quotesUpdatedTimeAgo = formatDistance(
              new Date(newDataItem.quote.USD.last_updated),
              new Date(),
              {unit: 'm'}
            ) + ' ago.';
          }

          result.push(newDataItem);
        }
      }

      return result;
    }, []);

    this.setFiltersMinMaxValues(processedCryptos);

    this.setState({
      dataLoaded: true,
      allRows: processedCryptos,
      quotesUpdatedTimeAgo,
      rows: processedCryptos.slice(0, pageSize)
    }, () => {
      if (filtersActive) {
        if (keyword) {
          ReactGA.pageview('/keyword=' + keyword);
        }
        this.getTagsData().then(({data}) => {
          this.setState({
            tagsData: data,
            tagsFunctional: this.getTagsByType(data, 'functional'),
            tagsTechnical: this.getTagsByType(data, 'technical')
          }, () => {
            this.filterRows(true);
          });
        });
      } else {
        this.orderRows();
        this.getTagsData().then(({data}) => {
          this.setState({
            tagsData: data,
            tagsFunctional: this.getTagsByType(data, 'functional'),
            tagsTechnical: this.getTagsByType(data, 'technical')
          });
        });
      }
    });
  };

  getTagsData = () => {
    const instance = axios.create({
      baseURL: tagsUrl
    });
    return instance.get();
  };

  getTagsByType = (tagsData, tagType) => {
    if (_.isEmpty(tagsData)) {
      return null;
    }

    const reducedTags = tagsData.reduce((functionalTags, tag) => {
      if (tag.type === tagType) {
        functionalTags.push({
          id: tag.id,
          name: tag.name,
          counter: tag.coin_counter
        });
      }
      return functionalTags;
    }, []);

    return _.orderBy(reducedTags, 'counter', 'desc');
  };

  getCMCData = () => {
    AWS.config.region = awsRegion;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    });
    AWS.config.credentials.get(() => {
      AWS.config.update({
        endpoint: dynamoDBEndpoint,
        dynamoDbCrc32: false
      });
      const docClient = new AWS.DynamoDB.DocumentClient();
      const params = {
        TableName: process.env.REACT_APP_CMC_TABLE
      };
      docClient.scan(params, (err, data) => {
        if (!err) {
          if (_.isEmpty(data.LastEvaluatedKey)) {
            this.processCMCData(data.Items);
            this.setState({cmcData: data.Items});
          } else {
            const newParams = {
              TableName: process.env.REACT_APP_CMC_TABLE,
              ExclusiveStartKey: data.LastEvaluatedKey
            };
            docClient.scan(newParams, (err, moreData) => {
              if (!err) {
                const allData = data.Items.concat(moreData.Items);
                this.processCMCData(allData);
                this.setState({cmcData: allData});
              } else {
                this.processCMCData();
              }
            })
          }
        } else {
          this.processCMCData();
        }
      });
    });
  };

  filterRows = (
    filtersActive,
    tagsFunctionalFilter,
    tagsTechnicalFilter,
    cmcRankFilterRange,
    inflationFilterRange,
    marketCapFilterRange,
    betaFilterRange,
    volumeFilterRange,
    change7dFilterRange,
    icoRaisedFilterRange,
    changeFromIcoFilterRange,
    change24hFilterRange,
    showOnlySelected
  ) => {
    const {
      allRows,
      keyword,
      tagsData,
      tagsFunctionalFilterMainView,
      tagsTechnicalFilterMainView,
      cmcRankFilterRangeMainView,
      inflationFilterRangeMainView,
      marketCapFilterRangeMainView,
      betaFilterRangeMainView,
      volumeFilterRangeMainView,
      change7dFilterRangeMainView,
      icoRaisedFilterRangeMainView,
      changeFromIcoFilterRangeMainView,
      change24hFilterRangeMainView,
      showOnlySelectedMainView,
      selectedRows
    } = this.state;

    if (
      !(
        cmcRankFilterRange &&
        inflationFilterRange &&
        marketCapFilterRange &&
        betaFilterRange &&
        volumeFilterRange &&
        change7dFilterRange &&
        icoRaisedFilterRange &&
        changeFromIcoFilterRange &&
        change24hFilterRange
      )
    ) {
      tagsFunctionalFilter = tagsFunctionalFilterMainView;
      tagsTechnicalFilter = tagsTechnicalFilterMainView;
      cmcRankFilterRange = cmcRankFilterRangeMainView;
      inflationFilterRange = inflationFilterRangeMainView;
      marketCapFilterRange = marketCapFilterRangeMainView;
      betaFilterRange = betaFilterRangeMainView;
      volumeFilterRange = volumeFilterRangeMainView;
      change7dFilterRange = change7dFilterRangeMainView;
      icoRaisedFilterRange = icoRaisedFilterRangeMainView;
      changeFromIcoFilterRange = changeFromIcoFilterRangeMainView;
      change24hFilterRange = change24hFilterRangeMainView;

      filtersActive = (
        !(
          !tagsFunctionalFilter &&
          !tagsTechnicalFilter &&
          !keyword &&
          _.isEmpty(cmcRankFilterRange) &&
          _.isEmpty(inflationFilterRange) &&
          _.isEmpty(marketCapFilterRange) &&
          _.isEmpty(betaFilterRange) &&
          _.isEmpty(volumeFilterRange) &&
          _.isEmpty(change7dFilterRange) &&
          _.isEmpty(icoRaisedFilterRange) &&
          _.isEmpty(changeFromIcoFilterRange) &&
          _.isEmpty(change24hFilterRange) &&
          !showOnlySelected
        )
      );
    }

    if (_.isUndefined(showOnlySelected)){
      showOnlySelected = showOnlySelectedMainView;
      if (showOnlySelected) {
        filtersActive = true;
      }
    }

    if (filtersActive) {
      const urlStringForGA = `/filtering/cmcRankFilterRange=${cmcRankFilterRange}
      &inflationFilterRange=${inflationFilterRange}
      &marketCapFilterRange=${marketCapFilterRange}
      &betaFilterRange=${betaFilterRange}
      &volumeFilterRange=${volumeFilterRange}
      &change7dFilterRange=${change7dFilterRange}
      &icoRaisedFilterRange=${icoRaisedFilterRange}
      &changeFromIcoFilterRange=${changeFromIcoFilterRange}
      &change24hFilterRange=${change24hFilterRange}
      &showOnlySelected=${showOnlySelected}
      &tagsFunctionalFilter=${tagsFunctionalFilter}
      &tagsTechnicalFilter=${tagsTechnicalFilter}`;
      ReactGA.pageview(urlStringForGA);
    }

    const filteredRows = _.filter(allRows, (row) => (
      (!keyword || (fuzzysearch(_.toLower(keyword), _.toLower(row.name))) || _.toLower(row.symbol) === _.toLower(keyword)) &&
      (keyword ||
      ((!showOnlySelected || selectedRows.includes(row.id)) &&
      (_.isEmpty(cmcRankFilterRange) || (row.cmc_rank >= cmcRankFilterRange[0] && row.cmc_rank <= cmcRankFilterRange[1])) &&
      doesRowPassTagsFilter(row, tagsData, tagsFunctionalFilter) &&
      doesRowPassTagsFilter(row, tagsData, tagsTechnicalFilter) &&
      doesRowPassRangeFilter(row, 'market_cap', marketCapFilterRange, [marketCapMinAdjusted, marketCapMaxAdjusted]) &&
      doesRowPassRangeFilter(row, 'future_inflation', inflationFilterRange, [inflationMinAdjusted, inflationMaxAdjusted]) &&
      doesRowPassRangeFilter(row, 'beta_value', betaFilterRange, [betaMinAdjusted, betaMaxAdjusted]) &&
      doesRowPassRangeFilter(row, 'volume_24h', volumeFilterRange, [volumeMinAdjusted, volumeMaxAdjusted]) &&
      doesRowPassRangeFilter(row, 'percent_change_7d', change7dFilterRange, [change7dMinAdjusted, change7dMaxAdjusted]) &&
      doesRowPassRangeFilter(row, 'raised_usd', icoRaisedFilterRange, [icoRaisedMinAdjusted, icoRaisedMaxAdjusted]) &&
      doesRowPassRangeFilter(row, 'pct_since_ico', changeFromIcoFilterRange, [changeFromIcoMinAdjusted, changeFromIcoMaxAdjusted]) &&
      doesRowPassRangeFilter(row, 'percent_change_24h', change24hFilterRange, [change24hMinAdjusted, change24hMaxAdjusted])))
    ));

    if (filtersActive !== undefined) {
      this.setState({
        filteredRows,
        filtersActive,
        tagsFunctionalFilterMainView: tagsFunctionalFilter,
        tagsTechnicalFilterMainView: tagsTechnicalFilter,
        cmcRankFilterRangeMainView: cmcRankFilterRange,
        inflationFilterRangeMainView: inflationFilterRange,
        marketCapFilterRangeMainView: marketCapFilterRange,
        betaFilterRangeMainView: betaFilterRange,
        volumeFilterRangeMainView: volumeFilterRange,
        change7dFilterRangeMainView: change7dFilterRange,
        icoRaisedFilterRangeMainView: icoRaisedFilterRange,
        changeFromIcoFilterRangeMainView: changeFromIcoFilterRange,
        change24hFilterRangeMainView: change24hFilterRange,
        showOnlySelectedMainView: showOnlySelected
      }, () => {
        this.orderRows();
      });
    } else {
      this.setState({
        filteredRows,
        tagsFunctionalFilterMainView: tagsFunctionalFilter,
        tagsTechnicalFilterMainView: tagsTechnicalFilter,
        cmcRankFilterRangeMainView: cmcRankFilterRange,
        inflationFilterRangeMainView: inflationFilterRange,
        marketCapFilterRangeMainView: marketCapFilterRange,
        betaFilterRangeMainView: betaFilterRange,
        volumeFilterRangeMainView: volumeFilterRange,
        change7dFilterRangeMainView: change7dFilterRange,
        icoRaisedFilterRangeMainView: icoRaisedFilterRange,
        changeFromIcoFilterRangeMainView: changeFromIcoFilterRange,
        change24hFilterRangeMainView: change24hFilterRange,
        showOnlySelectedMainView: showOnlySelected
      }, () => {
        this.orderRows();
      });
    }

  };

  handleFilterByKeyword = _.debounce((keyword) => {
    ReactGA.pageview('/keyword=' + keyword);
    this.setState({keyword}, () => {
      this.filterRows(true);
    });
  }, searchDebounceWaitMilisec);

  handleLoadMoreRows = (loadRowsTill) => {
    ReactGA.pageview(`/load-more-rows=${loadRowsTill}`);
    const { allRows, filteredRows, filtersActive } = this.state;

    this.setState(({ rows }) => ({
      loadedRowsCount: loadRowsTill,
      rows: (filtersActive ? filteredRows : allRows).slice(0, loadRowsTill)
    }));
  };

  getColumnName = (orderByColumnString) => (_.split(orderByColumnString, '-')[1]);

  handleOrderByColumn1 = (value) => {
    ReactGA.pageview('/col1/orderBy=' + value);
    const { orderByColumns } = this.state;

    // Check if column 2 is not the same, change to different one
    if (this.getColumnName(value) === this.getColumnName(orderByColumns[1])) {
      if (this.getColumnName(value) !== this.getColumnName(ORDER_BY_COLUMNS_LIST[1].value)) {
        orderByColumns[1] = ORDER_BY_COLUMNS_LIST[1].value;
      } else {
        orderByColumns[1] = ORDER_BY_COLUMNS_LIST[4].value;
      }
    }

    orderByColumns[0] = value;
    this.setState({orderByColumns}, () => {
      this.orderRows();
    });
  };

  handleOrderByColumn2 = (value) => {
    ReactGA.pageview('/col2/orderBy=' + value);
    const { orderByColumns } = this.state;
    orderByColumns[1] = value;
    this.setState({orderByColumns}, () => {
      this.orderRows();
    });
  };

  handleShowAllCryptos = (showAllCryptos) => {
    if (showAllCryptos) {
      ReactGA.pageview('/show-non-ico-cryptos');
    } else {
      ReactGA.pageview('/hide-non-ico-cryptos');
    }

    this.setState({showAllCryptos}, () => {
      const { cmcData } = this.state;
      this.processCMCData(cmcData);
    });
  };

  orderRows = () => {
    const { allRows, filteredRows, orderByColumns, filtersActive, loadedRowsCount } = this.state;
    let columns = [];
    let orders = [];
    _.map(orderByColumns, (orderByColumn) => {
      const orderByColumnArray = _.split(orderByColumn, '-');
      orders.push(orderByColumnArray[0]);
      columns.push(orderByColumnArray[1]);
    });
    const orderByColumn1Order = orders[0];
    const orderByColumn1 = columns[0];

    // Show empty or 0 values always at the end for most columns, not depending if ordering is asc or desc
    let orderedRows;
    if (_.includes(['percent_change_7d', 'percent_change_24h'], orderByColumn1)) {
      orderedRows = _.orderBy((filtersActive ? filteredRows : allRows), orderByColumn1, orderByColumn1Order);
    } else if (orderByColumn1 === 'percent_from_price_ath') {
      if (orders[0] === 'desc') {
        orderedRows = _.orderBy((filtersActive ? filteredRows : allRows), (row) => (row[orderByColumn1] || -101), orderByColumn1Order);
      } else {
        orderedRows = _.orderBy((filtersActive ? filteredRows : allRows), orderByColumn1, orderByColumn1Order);
      }
    } else if (orderByColumn1 === 'ico_date') {
      if (orders[0] === 'desc') {
        orderedRows = _.orderBy((filtersActive ? filteredRows : allRows), (row) => (
          (row[orderByColumn1] !== '2099-01-01T00:00:00.000Z') ? row[orderByColumn1] : ''
        ), orderByColumn1Order);
      } else {
        orderedRows = _.orderBy((filtersActive ? filteredRows : allRows), orderByColumn1, orderByColumn1Order);
      }
    } else {
      if (orders[0] === 'desc') {
        orderedRows = _.orderBy((filtersActive ? filteredRows : allRows), (row) => (row[orderByColumn1] || 0), orderByColumn1Order);
      } else {
        orderedRows = _.orderBy((filtersActive ? filteredRows : allRows), (row) => (
          (row[orderByColumn1] !== 0) ? row[orderByColumn1] : null
        ), orderByColumn1Order);
      }
    }

    orderedRows.map((row, idx) => {
      row.sort_idx = idx + 1;
      row.sortColumnName = columns[0];
      return row;
    });

    if (filtersActive) {
      this.setState({
        filteredRows: orderedRows,
        rows: orderedRows.slice(0, loadedRowsCount)
      });
    } else {
      this.setState({
        allRows: orderedRows,
        rows: orderedRows.slice(0, loadedRowsCount)
      });
    }
  };

  setFiltersVisible = (filtersVisible) => {
    this.setState({
      filtersVisible
    });
  };

  handleRowCheck = (selectedRows) => {
    this.setState({
      selectedRows
    });
  };

  render() {
    const {
      dataLoaded,
      rows,
      orderByColumns,
      allRows,
      filteredRows,
      filtersActive,
      quotesUpdatedTimeAgo,
      showAllCryptos,
      tagsFunctionalFilterMainView,
      tagsTechnicalFilterMainView,
      cmcRankFilterRangeMainView,
      inflationFilterRangeMainView,
      marketCapFilterRangeMainView,
      betaFilterRangeMainView,
      volumeFilterRangeMainView,
      change7dFilterRangeMainView,
      icoRaisedFilterRangeMainView,
      changeFromIcoFilterRangeMainView,
      change24hFilterRangeMainView,
      keyword,
      selectedRows,
      showOnlySelectedMainView,
      tagsFunctional,
      tagsTechnical,
      filtersVisible
    } = this.state;
    const { android } = this.props;

    const orderingKey = _.find(ORDER_BY_COLUMNS_LIST, {value: orderByColumns[0]}).key;
    let link = `${baseUrl}/fl/${showAllCryptos ? 't' : 'f'}/${orderingKey}
      /${_.isEmpty(cmcRankFilterRangeMainView) ? 'f' : cmcRankFilterRangeMainView}
      /${_.isEmpty(inflationFilterRangeMainView) ? 'f' : inflationFilterRangeMainView}
      /${_.isEmpty(marketCapFilterRangeMainView) ? 'f' : marketCapFilterRangeMainView}
      /${_.isEmpty(betaFilterRangeMainView) ? 'f' : betaFilterRangeMainView}
      /${_.isEmpty(volumeFilterRangeMainView) ? 'f' : volumeFilterRangeMainView}
      /${_.isEmpty(change7dFilterRangeMainView) ? 'f' : change7dFilterRangeMainView}
      /${_.isEmpty(icoRaisedFilterRangeMainView) ? 'f' : icoRaisedFilterRangeMainView}
      /${_.isEmpty(changeFromIcoFilterRangeMainView) ? 'f' : changeFromIcoFilterRangeMainView}
      /${_.isEmpty(change24hFilterRangeMainView) ? 'f' : change24hFilterRangeMainView}`;
    link = keyword ? `${link}/${keyword}` : `${link}/f`;
    link = link.replace(/\s/g, ""); //remove whitespace
    link = _.isEmpty(selectedRows) ? `${link}/f` : `${link}/${selectedRows}`;
    link = showOnlySelectedMainView ? `${link}/t` : `${link}/f`;
    link = tagsFunctionalFilterMainView ? `${link}/${tagsFunctionalFilterMainView}` : `${link}/f`;
    link = tagsTechnicalFilterMainView ? `${link}/${tagsTechnicalFilterMainView}` : `${link}/f`;

    return (
      <div className="MainView">
        {!dataLoaded && <Spinner />}
        {dataLoaded && _.isEmpty(rows) && <NoDataFound />}
        <BrowserView>
          <div className="TopContainer">
            <Logo />
            <OrderingComponent
              handleOrderByColumn1={this.handleOrderByColumn1}
              handleOrderByColumn2={this.handleOrderByColumn2}
              orderByColumns={orderByColumns}
              orderByColumnsList={ORDER_BY_COLUMNS_LIST}
            />
            <SearchBox
              searchText={keyword || ''}
              placeholder='Search'
              onChange={this.handleFilterByKeyword}
            />
            <SharingRow iconSize={25} padding={'1px 4px 1px 4px'} />
          </div>
        </BrowserView>
        <MobileView>
          <div className="TopMobileContainer">
            <Logo />
            <SearchBox
              searchText={keyword || ''}
              placeholder='Search'
              onChange={this.handleFilterByKeyword}
            />
            <OrderingComponent
              handleOrderByColumn1={this.handleOrderByColumn1}
              handleOrderByColumn2={this.handleOrderByColumn2}
              orderByColumns={orderByColumns}
              orderByColumnsList={ORDER_BY_COLUMNS_LIST}
            />
          </div>
        </MobileView>
        <FilteringComponent
          marketCapMax={marketCapMaxAdjusted}
          marketCapMin={marketCapMinAdjusted}
          cmcRankMax={cmcRankMax}
          cmcRankMin={cmcRankMin}
          inflationMax={inflationMaxAdjusted}
          inflationMin={inflationMinAdjusted}
          betaMax={betaMaxAdjusted}
          betaMin={betaMinAdjusted}
          volumeMax={volumeMaxAdjusted}
          volumeMin={volumeMinAdjusted}
          change7dMax={change7dMaxAdjusted}
          change7dMin={change7dMinAdjusted}
          icoRaisedMax={icoRaisedMaxAdjusted}
          icoRaisedMin={icoRaisedMinAdjusted}
          changeFromIcoMax={changeFromIcoMaxAdjusted}
          changeFromIcoMin={changeFromIcoMinAdjusted}
          change24hMax={change24hMaxAdjusted}
          change24hMin={change24hMinAdjusted}
          handleShowAllCryptosMainView={this.handleShowAllCryptos}
          filterRows={this.filterRows}
          showAllCryptosInitialValue={showAllCryptos}
          cmcRankFilterRangeInitialValue={cmcRankFilterRangeMainView}
          inflationFilterRangeInitialValue={inflationFilterRangeMainView}
          marketCapFilterRangeInitialValue={marketCapFilterRangeMainView}
          betaFilterRangeInitialValue={betaFilterRangeMainView}
          volumeFilterRangeInitialValue={volumeFilterRangeMainView}
          change7dFilterRangeInitialValue={change7dFilterRangeMainView}
          icoRaisedFilterRangeInitialValue={icoRaisedFilterRangeMainView}
          changeFromIcoFilterRangeInitialValue={changeFromIcoFilterRangeMainView}
          change24hFilterRangeInitialValue={change24hFilterRangeMainView}
          filtersActiveInitialValue={filtersActive}
          setFiltersVisible={this.setFiltersVisible}
          showOnlySelectedInitialValue={showOnlySelectedMainView}
          selectedRows={selectedRows}
          tagsFunctional={tagsFunctional}
          tagsTechnical={tagsTechnical}
          tagsFunctionalFilterInitialValue={tagsFunctionalFilterMainView}
          tagsTechnicalFilterInitialValue={tagsTechnicalFilterMainView}
          showingCryptosCount={filtersActive ? filteredRows.length : allRows.length}
        />
        <BrowserView>
          {
            !filtersVisible && (
                <div className="DonateFeedbackRow">
                  <FullLink link={link} android={android}/>
                  <Donate />
                  <HireDev />
                  <Feedback />
                </div>
            )
          }
          <CryptoTable
            rows={rows}
            handleLoadMoreRows={this.handleLoadMoreRows}
            totalRowsCount={(filtersActive ? filteredRows.length : allRows.length)}
            pageSize={pageSize}
            timesColumnLessPriority={timesColumnLessPriority}
            handleRowCheck={this.handleRowCheck}
            selectedRows={selectedRows}
            isMobile={isMobile}
          />
        </BrowserView>
        <MobileView>
          {
            !filtersVisible && (
              <div className="ShareMobileRow">
                <FullLink link={link} android={android}/>
              </div>
            )
          }
          <CryptoTableMobile
            rows={rows}
            handleLoadMoreRows={this.handleLoadMoreRows}
            totalRowsCount={(filtersActive ? filteredRows.length : allRows.length)}
            pageSize={pageSize}
            timesColumnLessPriority={timesColumnLessPriority}
            handleRowCheck={this.handleRowCheck}
            selectedRows={selectedRows}
            isMobile={isMobile}
          />
        </MobileView>
        <Disclaimer quotesUpdatedTimeAgo={quotesUpdatedTimeAgo} mobileApp={android}/>
      </div>
    );
  }
}

export default MainView;

MainView.propTypes = {
  initialOrderByColumn1: PropTypes.string,
  showAllCryptos: PropTypes.bool,
  android: PropTypes.bool
};
