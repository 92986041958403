import React from 'react';
import noDataFound from 'assets/NoDataFound.png';
import './NoDataFound.css';

export default function NoDataFound() {
  return (
    <div className="NoDataFoundBox">
      <img src={noDataFound} alt="No Data Found"/>
      <div className="NoDataFoundTextLine1">
        No ICO Data found with these filter values
      </div>
      <div className="NoDataFoundTextLine2">
        Move filters wider or remove keyword
      </div>
    </div>
  );
}
