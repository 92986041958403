import React from 'react';
import PropTypes from 'prop-types';

export default function NumberCellRenderer(row) {
  const { cellData, dataKey, rowData: { sortColumnName, market_cap_approximate } } = row;
  const cellClassName = (dataKey === sortColumnName) ? 'SortingColumnCell' : null;

  if (cellData) {
    return (
      <div className={cellClassName}>
        ${cellData.toLocaleString()}
      </div>
    );
  } else if (dataKey === 'market_cap' && market_cap_approximate > 0) {
    return (
      <div className={cellClassName}>
        {'< '}${market_cap_approximate.toLocaleString()}
      </div>
    );
  }

  return <div />;
}

NumberCellRenderer.propTypes = {
  row: PropTypes.shape({
    cellData: PropTypes.number,
    dataKey: PropTypes.string.isRequired,
    rowData: PropTypes.shape({
      sortColumnName: PropTypes.string
    })
  })
};
