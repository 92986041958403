const ORDER_BY_COLUMNS_LIST = [
  { key: 0, value: 'asc-market_cap', text: '&#x2197; Market Cap' },
  { key: 1, value: 'desc-market_cap', text: '&#x2198; Market Cap' },
  { key: 2, value: 'asc-times_to_get_back_to_ico_price', text: '&#x2197; Times to get back to ICO price' },
  { key: 3, value: 'desc-times_to_get_back_to_ico_price', text: '&#x2198; Times to get back to ICO price' },
  { key: 4, value: 'asc-future_inflation', text: '&#x2197; Future inflation' },
  { key: 5, value: 'desc-future_inflation', text: '&#x2198; Future inflation' },
  { key: 6, value: 'asc-raised_usd', text: '&#x2197; ICO raised value' },
  { key: 7, value: 'desc-raised_usd', text: '&#x2198; ICO raised value' },
  { key: 8, value: 'asc-beta_value', text: '&#x2197; Beta coef.' },
  { key: 9, value: 'desc-beta_value', text: '&#x2198; Beta coef.' },
  { key: 10, value: 'asc-volume_24h', text: '&#x2197; 24 hours volume' },
  { key: 11, value: 'desc-volume_24h', text: '&#x2198; 24 hours volume' },
  { key: 12, value: 'asc-percent_change_7d', text: '&#x2197; 7 days change' },
  { key: 13, value: 'desc-percent_change_7d', text: '&#x2198; 7 days change' },
  { key: 14, value: 'asc-percent_change_24h', text: '&#x2197; 24 hours change' },
  { key: 15, value: 'desc-percent_change_24h', text: '&#x2198; 24 hours change' },
  { key: 16, value: 'asc-pct_since_ico', text: '&#x2197; Change from ICO price' },
  { key: 17, value: 'desc-pct_since_ico', text: '&#x2198; Change from ICO price' },
  { key: 18, value: 'asc-percent_from_price_ath', text: '&#x2197; Change from ATH price' },
  { key: 19, value: 'desc-percent_from_price_ath', text: '&#x2198; Change from ATH price' },
  { key: 20, value: 'asc-ath_date', text: '&#x2197; All time high date' },
  { key: 21, value: 'desc-ath_date', text: '&#x2198; All time high date' },
  { key: 22, value: 'asc-ico_date', text: '&#x2197; ICO end date' },
  { key: 23, value: 'desc-ico_date', text: '&#x2198; ICO end date' },
  { key: 24, value: 'asc-volume_per_marketcap_pct', text: '&#x2197; Volume / Market cap ratio' },
  { key: 25, value: 'desc-volume_per_marketcap_pct', text: '&#x2198; Volume / Market cap ratio' },
  { key: 26, value: 'asc-percent_change_7d_adjusted', text: '&#x2197; 7 days compared to market' },
  { key: 27, value: 'desc-percent_change_7d_adjusted', text: '&#x2198; 7 days compared to market' },
  { key: 28, value: 'asc-percent_change_24h_adjusted', text: '&#x2197; 24 hours compared to market' },
  { key: 29, value: 'desc-percent_change_24h_adjusted', text: '&#x2198; 24 hours compared to market' },
  { key: 30, value: 'asc-cmc_rank', text: '&#x2197; CoinMarketCap rank' },
  { key: 31, value: 'desc-cmc_rank', text: '&#x2198; CoinMarketCap rank' }
];

export default ORDER_BY_COLUMNS_LIST;
