import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  BrowserView,
  MobileView
} from 'react-device-detect';
import './Disclaimer.css'

export default function Disclaimer({quotesUpdatedTimeAgo, mobileApp}) {
  return (
    <Fragment>
      <BrowserView>
        <div className="DisclaimerBox">
          <div>
            DISCLAIMER: Nothing on this {mobileApp ? 'app' : 'website'} is investment advice. Some of the data may be inaccurate. Do your own research!
          </div>
          <div>
            Data updated {quotesUpdatedTimeAgo}
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className="DisclaimerMobileBox">
          <div>
            DISCLAIMER: Nothing on this {mobileApp ? 'app' : 'website'} is investment advice.
          </div>
        </div>
      </MobileView>
    </Fragment>
  );
}

Disclaimer.propTypes = {
  quotesUpdatedTimeAgo: PropTypes.string,
  mobileApp: PropTypes.bool
};
