import React, { Component } from 'react';
import { Modal, Button } from 'antd';
import qrCode from 'assets/eth_325.png';
import ReactGA from 'react-ga';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ClockCircleOutlined, CopyOutlined } from '@ant-design/icons';
import './Donate.css'

class Donate extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false, copied: false }
  }

  showModal = () => {
    ReactGA.pageview(`/donate-ETH`);
    this.setState({
      visible: true,
    });
  };

  handleClose = (e) => {
    ReactGA.pageview(`/donate-ETH-close`);
    this.setState({
      visible: false,
    });
  };

  render() {
    const { visible } = this.state;
    return (
      <div>
        <div className="DonateBox">
          <div className="ETHLink">
            <Button type="secondary" size="small" icon={<ClockCircleOutlined />} onClick={this.showModal}>Donate some ETH to keep this tool alive</Button>
          </div>
        </div>
        <Modal
          title="Donate to this ETH Address: 0x84cf67679B55ebA02BAB51D8901951a92EC0BaF1"
          visible={visible}
          onCancel={this.handleClose}
          footer={[
            <Button key="close" onClick={this.handleClose} type="primary">
              Close
            </Button>,
          ]}
        >
          <CopyToClipboard text="0x84cf67679B55ebA02BAB51D8901951a92EC0BaF1" onCopy={() => this.setState({copied: true})}>
            <Button type="secondary" icon={<CopyOutlined />} block={true}>Copy ETH address to clipboard</Button>
          </CopyToClipboard>
          {this.state.copied ? <div className="ClipboardCopiedMsg">Copied.</div> : null}
          <img src={qrCode} className="QRCode" alt="ETH Address QR Code"/>
        </Modal>
      </div>
    );
  }
}

export default Donate;