import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import ReactGA from 'react-ga';
import Bookmark from 'react-bookmark';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { isMobile } from 'react-device-detect';
import SharingRow from 'components/SharingRow';
import './FullLink.css';
import { ShareAltOutlined, ChromeOutlined, CopyOutlined } from '@ant-design/icons';

class FullLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      copied: false
    }
  }

  showModal = () => {
    ReactGA.pageview(`/full-link-open`);
    this.setState({
      visible: true,
    });
  };

  handleClose = (e) => {
    ReactGA.pageview(`/full-link-close`);
    this.setState({
      visible: false,
    });
  };

  render() {
    const { visible } = this.state;
    const { link, android } = this.props;
    const saveWord1 = android ? '' : '/save';
    const saveWord2 = android ? '' : ' or save';
    return (
      <div>
        <div className={isMobile ? 'SaveCurrentViewButtonBoxMobile' : 'SaveCurrentViewButtonBox'}>
          <Button type={isMobile ? 'primary' : 'secondary'} size="small" icon={<ShareAltOutlined />} block={true} onClick={this.showModal}>
            {isMobile ? `Share${saveWord1} this view (incl. selected rows)` : `Share${saveWord1} this view (including selected rows)`}
          </Button>
        </div>
        <Modal
          title={`Share${saveWord2} your current view`}
          visible={visible}
          onCancel={this.handleClose}
          footer={[
            <Button key="close" onClick={this.handleClose} type="primary">
              Close
            </Button>,
          ]}
        >
          <div className="FullLinkAboveText">
            Unique link with your current settings (filters, tags, ordering, search keyword, row selection, etc.):
          </div>
          <div className="FullLink">
            {android ? <a href={link} target="_blank" rel="noopener noreferrer">{link}</a> : <a href={link}>{link}</a>}
          </div>
          {!android ? (
            <CopyToClipboard text={link} onCopy={() => this.setState({copied: true})}>
              <Button type="secondary" icon={<CopyOutlined />} block={true}>Copy this link to clipboard</Button>
            </CopyToClipboard>
          ) : (
            <Button href={link} target="_blank" rel="noopener noreferrer" type="secondary" icon={<ChromeOutlined />} block={true}>Open it in a browser</Button>
          )}
          {this.state.copied ? <div className="ClipboardCopiedMsg">Copied.</div> : null}
          <div className="ShareOrText">
            or share this link:
          </div>
          <SharingRow iconSize={40} padding={'15px 10px 5px 10px'} fullLink={link}/>
          {
            !android && (
              <Fragment>
                <div className="ShareOrText">
                  or
                </div>
                <div className="BookmarkComponentWrapper">
                  <Bookmark className="BookmarkComponentClass" href={link} title="FindCryptoGem" />
                </div>
              </Fragment>
            )
          }
        </Modal>
      </div>
    );
  }
}

export default FullLink;

FullLink.propTypes = {
  link: PropTypes.string,
  android: PropTypes.bool
};