import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import ReactGA from 'react-ga';
import _ from 'lodash';
import { Button, Checkbox, Collapse, Slider, Input, Select } from 'antd';
import {
  BrowserView,
  MobileView,
  isMobile
} from 'react-device-detect';
import './FilteringComponent.css'

const InputGroup = Input.Group;
const Option = Select.Option;
const dropdownStyle = {
  minWidth: 300
};
const dropdownStyleMobile = {
  minWidth: 220
};
const Panel = Collapse.Panel;
const customPanelStyle = {
  background: '#f7f7f7',
  borderRadius: 4,
  border: 0,
  overflow: 'hidden',
  position: 'relative',
  zIndex: 99,
  borderBottom: '1px solid lightgrey'
};

class FilteringComponent extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      tagsFunctionalFilterBefore: null,
      tagsTechnicalFilterBefore: null,
      cmcRankFilterRangeBefore: [],
      inflationFilterRangeBefore: [],
      marketCapFilterRangeBefore: [],
      betaFilterRangeBefore: [],
      volumeFilterRangeBefore: [],
      change7dFilterRangeBefore: [],
      icoRaisedFilterRangeBefore: [],
      changeFromIcoFilterRangeBefore: [],
      change24hFilterRangeBefore: [],
      collapseComponentActiveKeyBefore: [],
      showAllCryptosBefore: false,
      showOnlySelectedBefore: false,
      tagsFunctionalFilter: props.tagsFunctionalFilterInitialValue,
      tagsTechnicalFilter: props.tagsTechnicalFilterInitialValue,
      cmcRankFilterRange: props.cmcRankFilterRangeInitialValue,
      inflationFilterRange: props.inflationFilterRangeInitialValue,
      marketCapFilterRange: props.marketCapFilterRangeInitialValue,
      betaFilterRange: props.betaFilterRangeInitialValue,
      volumeFilterRange: props.volumeFilterRangeInitialValue,
      change7dFilterRange: props.change7dFilterRangeInitialValue,
      icoRaisedFilterRange: props.icoRaisedFilterRangeInitialValue,
      changeFromIcoFilterRange: props.changeFromIcoFilterRangeInitialValue,
      change24hFilterRange: props.change24hFilterRangeInitialValue,
      collapseComponentActiveKey: null,
      filtersActive: props.filtersActiveInitialValue,
      showAllCryptos: props.showAllCryptosInitialValue,
      showOnlySelected: props.showOnlySelectedInitialValue,
      loading: false
    };
  }

  handleResetFilters = () => {
    ReactGA.pageview('/reset-filters');
    this.setState({
      filtersActive: false,
      tagsFunctionalFilter: null,
      tagsTechnicalFilter: null,
      cmcRankFilterRange: [],
      inflationFilterRange: [],
      marketCapFilterRange: [],
      betaFilterRange: [],
      volumeFilterRange: [],
      change7dFilterRange: [],
      icoRaisedFilterRange: [],
      changeFromIcoFilterRange: [],
      change24hFilterRange: []
    });
  };

  handleShowOnlySelected = (e) => {
    const showOnlySelected = e.target.checked;
    this.setState({
      showOnlySelected
    });
  };

  areStateFiltersActive = () => {
    const {
      tagsFunctionalFilter,
      tagsTechnicalFilter,
      cmcRankFilterRange,
      inflationFilterRange,
      marketCapFilterRange,
      betaFilterRange,
      volumeFilterRange,
      change7dFilterRange,
      icoRaisedFilterRange,
      changeFromIcoFilterRange,
      change24hFilterRange
    } = this.state;
    return (
      !(
        tagsFunctionalFilter == null &&
        tagsTechnicalFilter == null &&
        _.isEmpty(cmcRankFilterRange) &&
        _.isEmpty(inflationFilterRange) &&
        _.isEmpty(marketCapFilterRange) &&
        _.isEmpty(betaFilterRange) &&
        _.isEmpty(volumeFilterRange) &&
        _.isEmpty(change7dFilterRange) &&
        _.isEmpty(icoRaisedFilterRange) &&
        _.isEmpty(changeFromIcoFilterRange) &&
        _.isEmpty(change24hFilterRange)
      )
    )
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      marketCapMax,
      marketCapMin,
      cmcRankMax,
      cmcRankMin,
      inflationMax,
      inflationMin,
      betaMax,
      betaMin,
      volumeMax,
      volumeMin,
      change7dMax,
      change7dMin,
      icoRaisedMax,
      icoRaisedMin,
      changeFromIcoMax,
      changeFromIcoMin,
      change24hMax,
      change24hMin
    } = this.props;

    let {
      tagsFunctionalFilter,
      tagsTechnicalFilter,
      cmcRankFilterRange,
      inflationFilterRange,
      marketCapFilterRange,
      betaFilterRange,
      volumeFilterRange,
      change7dFilterRange,
      icoRaisedFilterRange,
      changeFromIcoFilterRange,
      change24hFilterRange
    } = this.state;

    const tagsFunctionalFilterCurrentState = tagsFunctionalFilter;
    const tagsTechnicalFilterCurrentState = tagsTechnicalFilter;
    const cmcRankFilterRangeCurrentState = [...cmcRankFilterRange];
    const inflationFilterRangeCurrentState = [...inflationFilterRange];
    const marketCapFilterRangeCurrentState = [...marketCapFilterRange];
    const betaFilterRangeCurrentState = [...betaFilterRange];
    const volumeFilterRangeCurrentState = [...volumeFilterRange];
    const change7dFilterRangeCurrentState = [...change7dFilterRange];
    const icoRaisedFilterRangeCurrentState = [...icoRaisedFilterRange];
    const changeFromIcoFilterRangeCurrentState = [...changeFromIcoFilterRange];
    const change24hFilterRangeCurrentState = [...change24hFilterRange];

    // Reset filters in case min/max values changed and filters were not moved - when using non ico cryptos checkbox
    // Case when min/max change and one range end is changed:
    if (prevProps.cmcRankMin !== cmcRankMin && prevProps.cmcRankMin === cmcRankFilterRange[0]) {
      cmcRankFilterRange = [cmcRankMin, cmcRankFilterRange[1]];
    }
    if (prevProps.cmcRankMax !== cmcRankMax && prevProps.cmcRankMax === cmcRankFilterRange[1]) {
      cmcRankFilterRange = [cmcRankFilterRange[0], cmcRankMax];
    }

    if (prevProps.inflationMin !== inflationMin && prevProps.inflationMin === inflationFilterRange[0]) {
      inflationFilterRange = [inflationMin, inflationFilterRange[1]];
    }
    if (prevProps.inflationMax !== inflationMax && prevProps.inflationMax === inflationFilterRange[1]) {
      inflationFilterRange = [inflationFilterRange[0], inflationMax];
    }

    if (prevProps.marketCapMin !== marketCapMin && prevProps.marketCapMin === marketCapFilterRange[0]) {
      marketCapFilterRange = [marketCapMin, marketCapFilterRange[1]];
    }

    if (prevProps.marketCapMax !== marketCapMax && prevProps.marketCapMax === marketCapFilterRange[1]) {
      marketCapFilterRange = [marketCapFilterRange[0], marketCapMax];
    }

    if (prevProps.betaMin !== betaMin && prevProps.betaMin === betaFilterRange[0]) {
      betaFilterRange = [betaMin, betaFilterRange[1]];
    }
    if (prevProps.betaMax !== betaMax && prevProps.betaMax === betaFilterRange[1]) {
      betaFilterRange = [betaFilterRange[0], betaMax];
    }

    if (prevProps.volumeMin !== volumeMin && prevProps.volumeMin === volumeFilterRange[0]) {
      volumeFilterRange = [volumeMin, volumeFilterRange[1]];
    }
    if (prevProps.volumeMax !== volumeMax && prevProps.volumeMax === volumeFilterRange[1]) {
      volumeFilterRange = [volumeFilterRange[0], volumeMax];
    }

    if (prevProps.change7dMin !== change7dMin && prevProps.change7dMin === change7dFilterRange[0]) {
      change7dFilterRange = [change7dMin, change7dFilterRange[1]];
    }
    if (prevProps.change7dMax !== change7dMax && prevProps.change7dMax === change7dFilterRange[1]) {
      change7dFilterRange = [change7dFilterRange[0], change7dMax];
    }

    if (prevProps.icoRaisedMin !== icoRaisedMin && prevProps.icoRaisedMin === icoRaisedFilterRange[0]) {
      icoRaisedFilterRange = [icoRaisedMin, icoRaisedFilterRange[1]];
    }
    if (prevProps.icoRaisedMax !== icoRaisedMax && prevProps.icoRaisedMax === icoRaisedFilterRange[1]) {
      icoRaisedFilterRange = [icoRaisedFilterRange[0], icoRaisedMax];
    }

    if (prevProps.changeFromIcoMin !== changeFromIcoMin && prevProps.changeFromIcoMin === changeFromIcoFilterRange[0]) {
      changeFromIcoFilterRange = [changeFromIcoMin, changeFromIcoFilterRange[1]];
    }
    if (prevProps.changeFromIcoMax !== changeFromIcoMax && prevProps.changeFromIcoMax === changeFromIcoFilterRange[1]) {
      changeFromIcoFilterRange = [changeFromIcoFilterRange[0], changeFromIcoMax];
    }

    if (prevProps.change24hMin !== change24hMin && prevProps.change24hMin === change24hFilterRange[0]) {
      change24hFilterRange = [change24hMin, change24hFilterRange[1]];
    }
    if (prevProps.change24hMax !== change24hMax && prevProps.change24hMax === change24hFilterRange[1]) {
      change24hFilterRange = [change24hFilterRange[0], change24hMax];
    }

    // Case when min/max change and range was not changed:
    if (
      !(
        // eslint-disable-next-line
        prevState.tagsFunctionalFilter == tagsFunctionalFilter &&
        // eslint-disable-next-line
        prevState.tagsTechnicalFilter == tagsTechnicalFilter &&
        _.isEqual(prevState.cmcRankFilterRange, cmcRankFilterRange) &&
        _.isEqual(prevState.inflationFilterRange, inflationFilterRange) &&
        _.isEqual(prevState.marketCapFilterRange, marketCapFilterRange) &&
        _.isEqual(prevState.betaFilterRange, betaFilterRange) &&
        _.isEqual(prevState.volumeFilterRange, volumeFilterRange) &&
        _.isEqual(prevState.change7dFilterRange, change7dFilterRange) &&
        _.isEqual(prevState.icoRaisedFilterRange, icoRaisedFilterRange) &&
        _.isEqual(prevState.changeFromIcoFilterRange, changeFromIcoFilterRange) &&
        _.isEqual(prevState.change24hFilterRange, change24hFilterRange)
      )
    ) {
      if (cmcRankMin === cmcRankFilterRange[0] && cmcRankMax === cmcRankFilterRange[1]) {
        cmcRankFilterRange = [];
      }
      if (inflationMin === inflationFilterRange[0] && inflationMax === inflationFilterRange[1]) {
        inflationFilterRange = [];
      }
      if (marketCapMin === marketCapFilterRange[0] && marketCapMax === marketCapFilterRange[1]) {
        marketCapFilterRange = [];
      }
      if (betaMin === betaFilterRange[0] && betaMax === betaFilterRange[1]) {
        betaFilterRange = [];
      }
      if (volumeMin === volumeFilterRange[0] && volumeMax === volumeFilterRange[1]) {
        volumeFilterRange = [];
      }
      if (change7dMin === change7dFilterRange[0] && change7dMax === change7dFilterRange[1]) {
        change7dFilterRange = [];
      }

      if (icoRaisedMin === icoRaisedFilterRange[0] && icoRaisedMax === icoRaisedFilterRange[1]) {
        icoRaisedFilterRange = [];
      }
      if (changeFromIcoMin === changeFromIcoFilterRange[0] && changeFromIcoMax === changeFromIcoFilterRange[1]) {
        changeFromIcoFilterRange = [];
      }
      if (change24hMin === change24hFilterRange[0] && change24hMax === change24hFilterRange[1]) {
        change24hFilterRange = [];
      }

      const filtersActive = (
        !(
          tagsFunctionalFilter == null &&
          tagsTechnicalFilter == null &&
          _.isEmpty(cmcRankFilterRange) &&
          _.isEmpty(inflationFilterRange) &&
          _.isEmpty(marketCapFilterRange) &&
          _.isEmpty(betaFilterRange) &&
          _.isEmpty(volumeFilterRange) &&
          _.isEmpty(change7dFilterRange) &&
          _.isEmpty(icoRaisedFilterRange) &&
          _.isEmpty(changeFromIcoFilterRange) &&
          _.isEmpty(change24hFilterRange)
        )
      );

      if (
        !(
          // eslint-disable-next-line
          tagsFunctionalFilterCurrentState == tagsFunctionalFilter &&
          // eslint-disable-next-line
          tagsTechnicalFilterCurrentState == tagsTechnicalFilter &&
          _.isEqual(cmcRankFilterRangeCurrentState, cmcRankFilterRange) &&
          _.isEqual(inflationFilterRangeCurrentState, inflationFilterRange) &&
          _.isEqual(marketCapFilterRangeCurrentState, marketCapFilterRange) &&
          _.isEqual(betaFilterRangeCurrentState, betaFilterRange) &&
          _.isEqual(volumeFilterRangeCurrentState, volumeFilterRange) &&
          _.isEqual(change7dFilterRangeCurrentState, change7dFilterRange) &&
          _.isEqual(icoRaisedFilterRangeCurrentState, icoRaisedFilterRange) &&
          _.isEqual(changeFromIcoFilterRangeCurrentState, changeFromIcoFilterRange) &&
          _.isEqual(change24hFilterRangeCurrentState, change24hFilterRange)
        )
      ) {
        this.setState({
          tagsFunctionalFilter,
          tagsTechnicalFilter,
          cmcRankFilterRange,
          inflationFilterRange,
          marketCapFilterRange,
          betaFilterRange,
          volumeFilterRange,
          change7dFilterRange,
          icoRaisedFilterRange,
          changeFromIcoFilterRange,
          change24hFilterRange,
          filtersActive
        }, () => {
          if (!this.state.collapseComponentActiveKey) {
            this.handleFilterNow();
          }
        });
      }
    }
  }

  handleFilterNow = () => {
    this.setState({
      loading: true
    });

    setTimeout(async () => {
      this.setState({
        collapseComponentActiveKey: null
      });

      const {
        tagsFunctionalFilterBefore,
        tagsTechnicalFilterBefore,
        cmcRankFilterRangeBefore,
        inflationFilterRangeBefore,
        marketCapFilterRangeBefore,
        betaFilterRangeBefore,
        volumeFilterRangeBefore,
        change7dFilterRangeBefore,
        showAllCryptosBefore,
        showOnlySelectedBefore,
        icoRaisedFilterRangeBefore,
        changeFromIcoFilterRangeBefore,
        change24hFilterRangeBefore,
        tagsFunctionalFilter,
        tagsTechnicalFilter,
        cmcRankFilterRange,
        inflationFilterRange,
        marketCapFilterRange,
        betaFilterRange,
        volumeFilterRange,
        change7dFilterRange,
        icoRaisedFilterRange,
        changeFromIcoFilterRange,
        change24hFilterRange,
        showAllCryptos,
        showOnlySelected
      } = this.state;
      if (showAllCryptosBefore !== showAllCryptos) {
        await this.props.handleShowAllCryptosMainView(showAllCryptos);
      }

      if (
        !(
          // eslint-disable-next-line
          tagsFunctionalFilterBefore == tagsFunctionalFilter &&
          // eslint-disable-next-line
          tagsTechnicalFilterBefore == tagsTechnicalFilter &&
          _.isEqual(cmcRankFilterRangeBefore, cmcRankFilterRange) &&
          _.isEqual(inflationFilterRangeBefore, inflationFilterRange) &&
          _.isEqual(marketCapFilterRangeBefore, marketCapFilterRange) &&
          _.isEqual(betaFilterRangeBefore, betaFilterRange) &&
          _.isEqual(volumeFilterRangeBefore, volumeFilterRange) &&
          _.isEqual(change7dFilterRangeBefore, change7dFilterRange) &&
          _.isEqual(icoRaisedFilterRangeBefore, icoRaisedFilterRange) &&
          _.isEqual(changeFromIcoFilterRangeBefore, changeFromIcoFilterRange) &&
          _.isEqual(change24hFilterRangeBefore, change24hFilterRange) &&
          showAllCryptosBefore === showAllCryptos &&
          showOnlySelectedBefore === showOnlySelected
        )
      ) {
        const filtersActive = (
          !(
            !tagsFunctionalFilter &&
            !tagsTechnicalFilter &&
            _.isEmpty(cmcRankFilterRange) &&
            _.isEmpty(inflationFilterRange) &&
            _.isEmpty(marketCapFilterRange) &&
            _.isEmpty(betaFilterRange) &&
            _.isEmpty(volumeFilterRange) &&
            _.isEmpty(change7dFilterRange) &&
            _.isEmpty(icoRaisedFilterRange) &&
            _.isEmpty(changeFromIcoFilterRange) &&
            _.isEmpty(change24hFilterRange) &&
            !showOnlySelected
          )
        );

        this.props.filterRows(
          filtersActive,
          tagsFunctionalFilter,
          tagsTechnicalFilter,
          cmcRankFilterRange,
          inflationFilterRange,
          marketCapFilterRange,
          betaFilterRange,
          volumeFilterRange,
          change7dFilterRange,
          icoRaisedFilterRange,
          changeFromIcoFilterRange,
          change24hFilterRange,
          showOnlySelected
        );
      }
    }, 1);

    this.props.setFiltersVisible(false);
  };

  render() {
    const {
      marketCapMax,
      marketCapMin,
      cmcRankMax,
      cmcRankMin,
      inflationMax,
      inflationMin,
      betaMax,
      betaMin,
      volumeMax,
      volumeMin,
      change7dMax,
      change7dMin,
      icoRaisedMax,
      icoRaisedMin,
      changeFromIcoMax,
      changeFromIcoMin,
      change24hMax,
      change24hMin,
      selectedRows,
      tagsFunctional,
      tagsTechnical
    } = this.props;

    const {
      collapseComponentActiveKey,
      cmcRankFilterRange,
      inflationFilterRange,
      marketCapFilterRange,
      betaFilterRange,
      volumeFilterRange,
      change7dFilterRange,
      icoRaisedFilterRange,
      changeFromIcoFilterRange,
      change24hFilterRange,
      filtersActive,
      loading,
      showOnlySelected,
      tagsFunctionalFilter,
      tagsTechnicalFilter
    } = this.state;

    if (showOnlySelected && _.isEmpty(selectedRows)) {
      this.setState({showOnlySelected: false});
    }

    const handleFilterByMarketCap = (marketCapFilterRange) => {
      this.setState({marketCapFilterRange, filtersActive: true});
    };

    const handleFilterByInflation = (inflationFilterRange) => {
      this.setState({inflationFilterRange, filtersActive: true});
    };

    const handleFilterByCmcRank = (cmcRankFilterRange) => {
      this.setState({cmcRankFilterRange, filtersActive: true});
    };

    const handleFilterByBeta = (betaFilterRange) => {
      this.setState({betaFilterRange, filtersActive: true});
    };

    const handleFilterByVolume = (volumeFilterRange) => {
      this.setState({volumeFilterRange, filtersActive: true});
    };

    const handleFilterByChange7d = (change7dFilterRange) => {
      this.setState({change7dFilterRange, filtersActive: true});
    };

    const handleFilterByIcoRaised = (icoRaisedFilterRange) => {
      this.setState({icoRaisedFilterRange, filtersActive: true});
    };

    const handleFilterByChangeFromIco = (changeFromIcoFilterRange) => {
      this.setState({changeFromIcoFilterRange, filtersActive: true});
    };

    const handleFilterByChange24h = (change24hFilterRange) => {
      this.setState({change24hFilterRange, filtersActive: true});
    };

    const handleCollapseChange = (arr) => {
      const { setFiltersVisible } = this.props;
      this.setState({
        collapseComponentActiveKey: arr[0]
      });

      if (!arr[0]) {
        setFiltersVisible(false);
        ReactGA.pageview('/filters-close');
        this.handleFilterNow();
      } else {
        setFiltersVisible(true);
        this.setState({
          loading: false
        });
        ReactGA.pageview('/filters-open');

        const {
          tagsFunctionalFilter,
          tagsTechnicalFilter,
          cmcRankFilterRange,
          inflationFilterRange,
          marketCapFilterRange,
          betaFilterRange,
          volumeFilterRange,
          change7dFilterRange,
          icoRaisedFilterRange,
          changeFromIcoFilterRange,
          change24hFilterRange,
          collapseComponentActiveKey,
          showAllCryptos,
          showOnlySelected
        } = this.state;
        this.setState({
          tagsFunctionalFilterBefore: tagsFunctionalFilter,
          tagsTechnicalFilterBefore: tagsTechnicalFilter,
          cmcRankFilterRangeBefore: cmcRankFilterRange,
          inflationFilterRangeBefore: inflationFilterRange,
          marketCapFilterRangeBefore: marketCapFilterRange,
          betaFilterRangeBefore: betaFilterRange,
          volumeFilterRangeBefore: volumeFilterRange,
          change7dFilterRangeBefore: change7dFilterRange,
          icoRaisedFilterRangeBefore: icoRaisedFilterRange,
          changeFromIcoFilterRangeBefore: changeFromIcoFilterRange,
          change24hFilterRangeBefore: change24hFilterRange,
          collapseComponentActiveKeyBefore: collapseComponentActiveKey,
          showAllCryptosBefore: showAllCryptos,
          showOnlySelectedBefore: showOnlySelected
        });
      }
    };

    const handleFunctionalTagChange = (selectedValue) => {
      if (selectedValue) {
        this.setState({tagsFunctionalFilter: selectedValue, filtersActive: true});
      } else {
        this.setState({tagsFunctionalFilter: selectedValue}, () => {
          if (this.state.filtersActive !== this.areStateFiltersActive()) {
            this.setState({filtersActive: this.areStateFiltersActive()});
          }
        });
      }
    };

    const handleTechnicalTagChange = (selectedValue) => {
      if (selectedValue) {
        this.setState({tagsTechnicalFilter: selectedValue, filtersActive: true});
      } else {
        this.setState({tagsTechnicalFilter: selectedValue}, () => {
          if (this.state.filtersActive !== this.areStateFiltersActive()) {
            this.setState({filtersActive: this.areStateFiltersActive()});
          }
        });
      }
    };

    const renderFunctionalTagItems = () => {
      if (_.isEmpty(tagsFunctional)) {
        return null;
      }

      return tagsFunctional.map(tag => (
        <Option key={tag.id} value={tag.id}>
          <div>{`${tag.name} (<${tag.counter + 1})`}</div>
        </Option>
      ));
    };

    const renderTechnicalTagItems = () => {
      if (_.isEmpty(tagsTechnical)) {
        return null;
      }

      return tagsTechnical.map(tag => (
        <Option key={tag.id} value={tag.id}>
          <div>{`${tag.name} (<${tag.counter + 1})`}</div>
        </Option>
      ));
    };

    const handleShowAllCryptos = (e) => {
      const showAllCryptos = e.target.checked;
      this.setState({
        showAllCryptos
      });
    };

    const tipFormatter = (amount) => {
      return amount.toLocaleString();
    };

    const tipFormatterMarketCap = (amount) => {
      if (amount === marketCapMin) {
        return `$${amount.toLocaleString()} or less`;
      }

      if (amount === marketCapMax) {
        return `$${amount.toLocaleString()} or more`;
      }

      return `$${amount.toLocaleString()}`;
    };

    const tipFormatterInflation = (amount) => {
      if (amount === inflationMin) {
        return `${amount.toLocaleString()}x or less`;
      }

      if (amount === inflationMax) {
        return `${amount.toLocaleString()}x or more`;
      }

      return `${amount.toLocaleString()}x`;
    };

    const tipFormatterBeta = (amount) => {
      if (amount === betaMin) {
        return `${amount.toLocaleString()} or less`;
      }

      if (amount === betaMax) {
        return `${amount.toLocaleString()} or more`;
      }

      return amount.toLocaleString();
    };

    const tipFormatterVolume = (amount) => {
      if (amount === volumeMin) {
        return `$${amount.toLocaleString()} or less`;
      }

      if (amount === volumeMax) {
        return `$${amount.toLocaleString()} or more`;
      }

      return `$${amount.toLocaleString()}`;
    };

    const tipFormatterChange7d = (amount) => {
      if (amount === change7dMin) {
        return `${amount.toLocaleString()}% or less`;
      }

      if (amount === change7dMax) {
        return `${amount.toLocaleString()}% or more`;
      }

      return `${amount.toLocaleString()}%`;
    };

    const tipFormatterIcoRaised = (amount) => {
      if (amount === icoRaisedMin) {
        return `$${amount.toLocaleString()} or less`;
      }

      if (amount === icoRaisedMax) {
        return `$${amount.toLocaleString()} or more`;
      }

      return `$${amount.toLocaleString()}`;
    };

    const tipFormatterChangeFromIco = (amount) => {
      if (amount === changeFromIcoMin) {
        return `${amount.toLocaleString()}% or less`;
      }

      if (amount === changeFromIcoMax) {
        return `${amount.toLocaleString()}% or more`;
      }

      return `${amount.toLocaleString()}%`;
    };

    const tipFormatterChange24h = (amount) => {
      if (amount === change24hMin) {
        return `${amount.toLocaleString()}% or less`;
      }

      if (amount === change24hMax) {
        return `${amount.toLocaleString()}% or more`;
      }

      return `${amount.toLocaleString()}%`;
    };

    const marketCapMarks = {};
    marketCapMarks[marketCapMin] = `< $${marketCapMin.toLocaleString()}`;
    marketCapMarks[marketCapMax] = `$${marketCapMax.toLocaleString()}+`;
    const cmcRankMarks = {};
    cmcRankMarks[cmcRankMin] = `${cmcRankMin.toLocaleString()}`;
    cmcRankMarks[cmcRankMax] = `${cmcRankMax.toLocaleString()}`;
    const inflationMarks = {};
    inflationMarks[inflationMin] = `< ${inflationMin.toLocaleString()}x`;
    inflationMarks[inflationMax] = `${inflationMax.toLocaleString()}x+`;
    const betaMarks = {};
    betaMarks[betaMin] = `< ${betaMin.toLocaleString()}`;
    betaMarks[betaMax] = `${betaMax.toLocaleString()}+`;
    const volumeMarks = {};
    volumeMarks[volumeMin] = `< $${volumeMin.toLocaleString()}`;
    volumeMarks[volumeMax] = `$${volumeMax.toLocaleString()}+`;
    const change7dMarks = {};
    change7dMarks[change7dMin] = `< ${change7dMin.toLocaleString()}%`;
    change7dMarks[change7dMax] = `${change7dMax.toLocaleString()}%+`;
    const icoRaisedMarks = {};
    icoRaisedMarks[icoRaisedMin] = `< $${icoRaisedMin.toLocaleString()}`;
    icoRaisedMarks[icoRaisedMax] = `$${icoRaisedMax.toLocaleString()}+`;
    const changeFromIcoMarks = {};
    changeFromIcoMarks[changeFromIcoMin] = `< ${changeFromIcoMin.toLocaleString()}%`;
    changeFromIcoMarks[changeFromIcoMax] = `${changeFromIcoMax.toLocaleString()}%+`;
    const change24hMarks = {};
    change24hMarks[change24hMin] = `< ${change24hMin.toLocaleString()}%`;
    change24hMarks[change24hMax] = `${change24hMax.toLocaleString()}%+`;

    return (
      <Collapse bordered={true} onChange={handleCollapseChange} activeKey={collapseComponentActiveKey}>
        <Panel
            header="Click here to show / hide FILTERS"
            key="1" style={customPanelStyle}
            disabled={marketCapMax === 0}
            extra={!isMobile && this.props.showingCryptosCount && (
                <div className="ShowingCryptosText">
                  Showing {this.props.showingCryptosCount} cryptos ({selectedRows && selectedRows.length} selected)
                </div>
            )}
        >

          <BrowserView>
            <div className="FilteringComponent">
              <div className = "FilterMainTitle">
                Filters:
              </div>
              <div className="AllFiltersBox">
                <div className="FiltersRowContainer">
                  <div className="FilterBox">
                    <div className = "FilterName">
                      Market Cap:
                    </div>
                    <div className = "FilterSlider">
                      <Slider
                        range
                        marks={marketCapMarks}
                        min={marketCapMin}
                        max={marketCapMax}
                        tipFormatter={tipFormatterMarketCap}
                        onChange={handleFilterByMarketCap}
                        value={_.isEmpty(marketCapFilterRange) ? [marketCapMin, marketCapMax] : marketCapFilterRange}
                      />
                    </div>
                  </div>
                  <div className="FilterBox">
                    <div className = "FilterName">
                      CMC Rank:
                    </div>
                    <div className = "FilterSlider">
                      <Slider
                        range
                        marks={cmcRankMarks}
                        min={cmcRankMin}
                        max={cmcRankMax}
                        tipFormatter={tipFormatter}
                        onChange={handleFilterByCmcRank}
                        value={_.isEmpty(cmcRankFilterRange) ? [cmcRankMin, cmcRankMax] : cmcRankFilterRange}
                      />
                    </div>
                  </div>
                  <div className="FilterBox">
                    <div className = "FilterName">
                      Max Inflation:
                    </div>
                    <div className = "FilterSlider">
                      <Slider
                        range
                        marks={inflationMarks}
                        min={inflationMin}
                        max={inflationMax}
                        step={0.05}
                        tipFormatter={tipFormatterInflation}
                        onChange={handleFilterByInflation}
                        value={_.isEmpty(inflationFilterRange) ? [inflationMin, inflationMax] : inflationFilterRange}
                      />
                    </div>
                  </div>
                </div>
                <div className="FiltersRowContainer">

                  <div className="FilterBox">
                    <div className = "FilterName">
                      Beta coef.:
                    </div>
                    <div className = "FilterSlider">
                      <Slider
                        range
                        marks={betaMarks}
                        min={betaMin}
                        max={betaMax}
                        step={0.01}
                        tipFormatter={tipFormatterBeta}
                        onChange={handleFilterByBeta}
                        value={_.isEmpty(betaFilterRange) ? [betaMin, betaMax] : betaFilterRange}
                      />
                    </div>
                  </div>

                  <div className="FilterBox">
                    <div className = "FilterName">
                      24h volume:
                    </div>
                    <div className = "FilterSlider">
                      <Slider
                        range
                        marks={volumeMarks}
                        min={volumeMin}
                        max={volumeMax}
                        step={1}
                        tipFormatter={tipFormatterVolume}
                        onChange={handleFilterByVolume}
                        value={_.isEmpty(volumeFilterRange) ? [volumeMin, volumeMax] : volumeFilterRange}
                      />
                    </div>
                  </div>

                  <div className="FilterBox">
                    <div className = "FilterName">
                      7 days change:
                    </div>
                    <div className = "FilterSlider">
                      <Slider
                        range
                        marks={change7dMarks}
                        min={change7dMin}
                        max={change7dMax}
                        step={0.05}
                        tipFormatter={tipFormatterChange7d}
                        onChange={handleFilterByChange7d}
                        value={_.isEmpty(change7dFilterRange) ? [change7dMin, change7dMax] : change7dFilterRange}
                      />
                    </div>
                  </div>

                </div>

                <div className="FiltersRowContainer">

                  <div className="FilterBox">
                    <div className = "FilterName">
                      ICO raised:
                    </div>
                    <div className = "FilterSlider">
                      <Slider
                        range
                        marks={icoRaisedMarks}
                        min={icoRaisedMin}
                        max={icoRaisedMax}
                        step={1}
                        tipFormatter={tipFormatterIcoRaised}
                        onChange={handleFilterByIcoRaised}
                        value={_.isEmpty(icoRaisedFilterRange) ? [icoRaisedMin, icoRaisedMax] : icoRaisedFilterRange}
                      />
                    </div>
                  </div>

                  <div className="FilterBox">
                    <div className = "FilterName">
                      Change from ICO:
                    </div>
                    <div className = "FilterSlider">
                      <Slider
                        range
                        marks={changeFromIcoMarks}
                        min={changeFromIcoMin}
                        max={changeFromIcoMax}
                        step={0.05}
                        tipFormatter={tipFormatterChangeFromIco}
                        onChange={handleFilterByChangeFromIco}
                        value={_.isEmpty(changeFromIcoFilterRange) ? [changeFromIcoMin, changeFromIcoMax] : changeFromIcoFilterRange}
                      />
                    </div>
                  </div>

                  <div className="FilterBox">
                    <div className = "FilterName">
                      24 hours change:
                    </div>
                    <div className = "FilterSlider">
                      <Slider
                        range
                        marks={change24hMarks}
                        min={change24hMin}
                        max={change24hMax}
                        step={0.05}
                        tipFormatter={tipFormatterChange24h}
                        onChange={handleFilterByChange24h}
                        value={_.isEmpty(change24hFilterRange) ? [change24hMin, change24hMax] : change24hFilterRange}
                      />
                    </div>
                  </div>

                </div>

                <div className="BottomRowContainer">
                  <div className="TagsDropdownComponent">
                    <div className="TagsDropdownTitle">
                      Functional tag:
                    </div>
                    <div className="TagsDropdown">
                      <InputGroup compact>
                        <Select value={tagsFunctionalFilter} onChange={handleFunctionalTagChange} style={dropdownStyle} id="2" allowClear>
                          {renderFunctionalTagItems()}
                        </Select>
                      </InputGroup>
                    </div>
                  </div>
                  <div className="TagsDropdownComponent">
                    <div className="TagsDropdownTitle">
                      Technical tag:
                    </div>
                    <div className="TagsDropdown">
                      <InputGroup compact>
                        <Select value={tagsTechnicalFilter} onChange={handleTechnicalTagChange} style={dropdownStyle} id="3" allowClear>
                          {renderTechnicalTagItems()}
                        </Select>
                      </InputGroup>
                    </div>
                  </div>
                </div>

                <div className="BottomRowContainer">
                  <Checkbox checked={this.state.showAllCryptos} onChange={handleShowAllCryptos}>Show cryptocurrencies which did not have ICO or ICO data is not available</Checkbox>
                  <Checkbox checked={showOnlySelected} onChange={this.handleShowOnlySelected} disabled={_.isEmpty(selectedRows)}>Show only selected rows</Checkbox>
                  <Button type="secondary" onClick={this.handleResetFilters} disabled={!filtersActive}>Reset all filters</Button>
                </div>

                <Button className="FilterButtonContainer" type="primary" loading={loading} onClick={this.handleFilterNow}>Filter now!</Button>
              </div>
            </div>
          </BrowserView>

          <MobileView>
            <div className="FilteringComponent">
              <div className="AllFiltersBox">
                <div className="FilterBoxMobile">
                  <div className = "FilterNameMobile">
                    Market Cap:
                  </div>
                  <div className = "FilterSliderMobile">
                    <Slider
                      range
                      marks={marketCapMarks}
                      min={marketCapMin}
                      max={marketCapMax}
                      tipFormatter={tipFormatterMarketCap}
                      onChange={handleFilterByMarketCap}
                      value={_.isEmpty(marketCapFilterRange) ? [marketCapMin, marketCapMax] : marketCapFilterRange}
                    />
                  </div>
                </div>
                <div className="FilterBoxMobile">
                  <div className = "FilterNameMobile">
                    CMC Rank:
                  </div>
                  <div className = "FilterSliderMobile">
                    <Slider
                      range
                      marks={cmcRankMarks}
                      min={cmcRankMin}
                      max={cmcRankMax}
                      tipFormatter={tipFormatter}
                      onChange={handleFilterByCmcRank}
                      value={_.isEmpty(cmcRankFilterRange) ? [cmcRankMin, cmcRankMax] : cmcRankFilterRange}
                    />
                  </div>
                </div>
                <div className="FilterBoxMobile">
                  <div className = "FilterNameMobile">
                    Max Inflation:
                  </div>
                  <div className = "FilterSliderMobile">
                    <Slider
                      range
                      marks={inflationMarks}
                      min={inflationMin}
                      max={inflationMax}
                      step={0.05}
                      tipFormatter={tipFormatterInflation}
                      onChange={handleFilterByInflation}
                      value={_.isEmpty(inflationFilterRange) ? [inflationMin, inflationMax] : inflationFilterRange}
                    />
                  </div>
                </div>
                <div className="FilterBoxMobile">
                  <div className = "FilterNameMobile">
                    Beta coef.:
                  </div>
                  <div className = "FilterSliderMobile">
                    <Slider
                      range
                      marks={betaMarks}
                      min={betaMin}
                      max={betaMax}
                      step={0.01}
                      tipFormatter={tipFormatterBeta}
                      onChange={handleFilterByBeta}
                      value={_.isEmpty(betaFilterRange) ? [betaMin, betaMax] : betaFilterRange}
                    />
                  </div>
                </div>

                <div className="FilterBoxMobile">
                  <div className = "FilterNameMobile">
                    24h volume:
                  </div>
                  <div className = "FilterSliderMobile">
                    <Slider
                      range
                      marks={volumeMarks}
                      min={volumeMin}
                      max={volumeMax}
                      step={1}
                      tipFormatter={tipFormatterVolume}
                      onChange={handleFilterByVolume}
                      value={_.isEmpty(volumeFilterRange) ? [volumeMin, volumeMax] : volumeFilterRange}
                    />
                  </div>
                </div>

                <div className="FilterBoxMobile">
                  <div className = "FilterNameMobile">
                    7 days change:
                  </div>
                  <div className = "FilterSliderMobile">
                    <Slider
                      range
                      marks={change7dMarks}
                      min={change7dMin}
                      max={change7dMax}
                      step={0.05}
                      tipFormatter={tipFormatterChange7d}
                      onChange={handleFilterByChange7d}
                      value={_.isEmpty(change7dFilterRange) ? [change7dMin, change7dMax] : change7dFilterRange}
                    />
                  </div>
                </div>

                <div className="FilterBoxMobile">
                  <div className = "FilterNameMobile">
                    ICO raised:
                  </div>
                  <div className = "FilterSliderMobile">
                    <Slider
                      range
                      marks={icoRaisedMarks}
                      min={icoRaisedMin}
                      max={icoRaisedMax}
                      step={1}
                      tipFormatter={tipFormatterIcoRaised}
                      onChange={handleFilterByIcoRaised}
                      value={_.isEmpty(icoRaisedFilterRange) ? [icoRaisedMin, icoRaisedMax] : icoRaisedFilterRange}
                    />
                  </div>
                </div>

                <div className="FilterBoxMobile">
                  <div className = "FilterNameMobile">
                    Change from ICO:
                  </div>
                  <div className = "FilterSliderMobile">
                    <Slider
                      range
                      marks={changeFromIcoMarks}
                      min={changeFromIcoMin}
                      max={changeFromIcoMax}
                      step={0.05}
                      tipFormatter={tipFormatterChangeFromIco}
                      onChange={handleFilterByChangeFromIco}
                      value={_.isEmpty(changeFromIcoFilterRange) ? [changeFromIcoMin, changeFromIcoMax] : changeFromIcoFilterRange}
                    />
                  </div>
                </div>

                <div className="FilterBoxMobile">
                  <div className = "FilterNameMobile">
                    24h change:
                  </div>
                  <div className = "FilterSliderMobile">
                    <Slider
                      range
                      marks={change24hMarks}
                      min={change24hMin}
                      max={change24hMax}
                      step={0.05}
                      tipFormatter={tipFormatterChange24h}
                      onChange={handleFilterByChange24h}
                      value={_.isEmpty(change24hFilterRange) ? [change24hMin, change24hMax] : change24hFilterRange}
                    />
                  </div>
                </div>

                <div className="BottomMobileContainer">

                  <div className="TagsDropdownComponent">
                    <div className="TagsDropdownTitle">
                      Functional tag:
                    </div>
                    <div className="TagsDropdown">
                      <InputGroup compact>
                        <Select value={tagsFunctionalFilter} onChange={handleFunctionalTagChange} style={dropdownStyleMobile} id="2" allowClear>
                          {renderFunctionalTagItems()}
                        </Select>
                      </InputGroup>
                    </div>
                  </div>
                  <div className="TagsDropdownComponent">
                    <div className="TagsDropdownTitle">
                      Technical tag:
                    </div>
                    <div className="TagsDropdown">
                      <InputGroup compact>
                        <Select value={tagsTechnicalFilter} onChange={handleTechnicalTagChange} style={dropdownStyleMobile} id="3" allowClear>
                          {renderTechnicalTagItems()}
                        </Select>
                      </InputGroup>
                    </div>
                  </div>

                  <div className="FiltersCheckboxMobile">
                    <Checkbox checked={this.state.showAllCryptos} onChange={handleShowAllCryptos}>Show cryptocurrencies which did not have ICO or ICO data is not available</Checkbox>
                  </div>
                  <div className="FiltersCheckboxMobile">
                    <Checkbox checked={showOnlySelected} onChange={this.handleShowOnlySelected} disabled={_.isEmpty(selectedRows)}>Show only selected rows</Checkbox>
                  </div>
                  <Button className="FilterButtonMobileContainer" type="primary" loading={loading} onClick={this.handleFilterNow}>Filter now!</Button>
                  <Button type="secondary" onClick={this.handleResetFilters} disabled={!filtersActive}>Reset all filters</Button>
                </div>
              </div>
            </div>
          </MobileView>

        </Panel>
      </Collapse>
    );
  }

}

FilteringComponent.propTypes = {
  marketCapMax: PropTypes.number.isRequired,
  marketCapMin: PropTypes.number.isRequired,
  cmcRankMax: PropTypes.number.isRequired,
  cmcRankMin: PropTypes.number.isRequired,
  inflationMax: PropTypes.number.isRequired,
  inflationMin: PropTypes.number.isRequired,
  betaMax: PropTypes.number.isRequired,
  betaMin: PropTypes.number.isRequired,
  volumeMax: PropTypes.number.isRequired,
  volumeMin: PropTypes.number.isRequired,
  change7dMax: PropTypes.number.isRequired,
  change7dMin: PropTypes.number.isRequired,
  icoRaisedMax: PropTypes.number.isRequired,
  icoRaisedMin: PropTypes.number.isRequired,
  changeFromIcoMax: PropTypes.number.isRequired,
  changeFromIcoMin: PropTypes.number.isRequired,
  change24hMax: PropTypes.number.isRequired,
  change24hMin: PropTypes.number.isRequired,
  handleShowAllCryptosMainView: PropTypes.func.isRequired,
  filterRows: PropTypes.func.isRequired,
  showAllCryptosInitialValue: PropTypes.bool.isRequired,
  cmcRankFilterRangeInitialValue: PropTypes.array.isRequired,
  inflationFilterRangeInitialValue: PropTypes.array.isRequired,
  marketCapFilterRangeInitialValue: PropTypes.array.isRequired,
  betaFilterRangeInitialValue: PropTypes.array.isRequired,
  volumeFilterRangeInitialValue: PropTypes.array.isRequired,
  change7dFilterRangeInitialValue: PropTypes.array.isRequired,
  icoRaisedFilterRangeInitialValue: PropTypes.array.isRequired,
  changeFromIcoFilterRangeInitialValue: PropTypes.array.isRequired,
  change24hFilterRangeInitialValue: PropTypes.array.isRequired,
  filtersActiveInitialValue: PropTypes.bool.isRequired,
  showOnlySelectedInitialValue: PropTypes.bool.isRequired,
  selectedRows: PropTypes.array.isRequired,
  tagsFunctional: PropTypes.array.isRequired,
  tagsTechnical: PropTypes.array.isRequired,
  tagsFunctionalFilterInitialValue: PropTypes.string,
  tagsTechnicalFilterInitialValue: PropTypes.string,
  setFiltersVisible: PropTypes.func.isRequired,
  showingCryptosCount: PropTypes.number.isRequired
};

export default FilteringComponent;
