import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Table, Column } from 'sticky-react-table';
import { Tooltip } from 'antd';
import HeaderCell from './HeaderCell';
import PercentCellRenderer from './PercentCellRenderer';
import RegularCellRenderer from './RegularCellRenderer';
import NumberCellRenderer from './NumberCellRenderer';
import RoundedNumberCellRenderer from './RoundedNumberCellRenderer';
import TimesCellRenderer from './TimesCellRenderer';
import DateCellRenderer from './DateCellRenderer';
import LinkCellRenderer from './LinkCellRenderer';
import './CryptoTable.css'

const columnsSortableByClickingOnHeader = false;

export default function CryptoTable({
  rows,
  handleLoadMoreRows,
  totalRowsCount,
  pageSize,
  timesColumnLessPriority,
  handleRowCheck,
  selectedRows
}) {
  const fixedNumberOfColumns = 3;
  return (
    <div className="CryptoTable">
      <Table
        data={rows}
        rowSelection={true}
        onRowCheck={handleRowCheck}
        selectedRows={selectedRows}
        idKey="id"
        fixed={fixedNumberOfColumns}
        headerClassName="HeaderRow"
        infiniteScrollPageSize={pageSize}
        infiniteScrollThreshold={5}
        infiniteScrollLoadMore={handleLoadMoreRows}
        infiniteScrollTotalCount={totalRowsCount || 3000}
      >
        <Column
          title="Nr"
          width={55}
          dataKey="sort_idx"
          isSortable={columnsSortableByClickingOnHeader}
          headerRenderer={() => (
            <Tooltip title="Click on any number cell to open that project website in a new tab">
              <strong>Nr <br /> (<div className="HeaderCellLink">link</div>)</strong>
            </Tooltip>
          )}
          cellRenderer={row => LinkCellRenderer(row, row.rowData.url_website, 'project website')}
        />
        <Column
          title="Symbol"
          width={100}
          headerRenderer={() => (
            <Tooltip title="Click on any cell to open CoinMarketCap.com link of the project">
              <strong>Symbol <br /> (<div className="HeaderCellLink">CMC link</div>)</strong>
            </Tooltip>
          )}
          dataKey="symbol"
          isSortable={columnsSortableByClickingOnHeader}
          cellRenderer={row => {
            const tooltipTitle = `Open ${row.rowData.name} (${row.rowData.symbol}) on Coin Market Cap website`;
            return (
              <a href={row.rowData.cmc_link} target="_blank" rel="noopener noreferrer">
                <Tooltip title={tooltipTitle}>
                  <div className="CellWithLink">
                    <img src={row.rowData.icon} alt={row.rowData.symbol}/> {row.rowData.symbol}
                  </div>
                </Tooltip>
              </a>
            )
          }}
        />
        <Column
          title="Name"
          width={135}
          dataKey="name"
          isSortable={columnsSortableByClickingOnHeader}
          headerRenderer={() => (
            <Tooltip title="Click on any cell to open CoinPaprika.com link of the project">
              <strong>Name <br /> (<div className="HeaderCellLink">CP link</div>)</strong>
           </Tooltip>
          )}
          cellRenderer={row => LinkCellRenderer(row, row.rowData.cp_link, 'on Coin Paprika website')}
        />
        <Column
          title="CMC rank"
          width={60}
          dataKey="cmc_rank"
          isSortable={columnsSortableByClickingOnHeader}
          headerRenderer={() => (
            <Tooltip title="Click on any cell to open Telegram group of the project">
              <strong>CMC rank <br /> (<div className="HeaderCellLink">chat</div>)</strong>
            </Tooltip>
          )}
          cellRenderer={row => LinkCellRenderer(row, row.rowData.url_chat, 'chat')}
        />
        <Column
          title="Market cap"
          width={120}
          dataKey="market_cap"
          isSortable={columnsSortableByClickingOnHeader}
          headerRenderer={HeaderCell}
          cellRenderer={NumberCellRenderer}
        />
        <Column
          title="7 days change"
          width={100}
          dataKey="percent_change_7d"
          isSortable={columnsSortableByClickingOnHeader}
          headerRenderer={HeaderCell}
          cellRenderer={row => PercentCellRenderer(row, null, null)}
        />
        <Column
          title="24 hours change"
          width={100}
          dataKey="percent_change_24h"
          isSortable={columnsSortableByClickingOnHeader}
          headerRenderer={HeaderCell}
          cellRenderer={row => PercentCellRenderer(row, null, null)}
        />
        <Column
            title="7 days compared to market"
            width={100}
            dataKey="percent_change_7d_adjusted"
            isSortable={columnsSortableByClickingOnHeader}
            headerRenderer={HeaderCell}
            cellRenderer={row => PercentCellRenderer(row, null, null)}
        />
        <Column
          title="24 hours compared to market"
          width={100}
          dataKey="percent_change_24h_adjusted"
          isSortable={columnsSortableByClickingOnHeader}
          headerRenderer={HeaderCell}
          cellRenderer={row => PercentCellRenderer(row, null, null)}
        />
        <Column
          title="24 hours volume"
          width={120}
          dataKey="volume_24h"
          isSortable={columnsSortableByClickingOnHeader}
          headerRenderer={HeaderCell}
          cellRenderer={RoundedNumberCellRenderer}
        />
        <Column
          title="Volume / Market cap ratio"
          width={95}
          dataKey="volume_per_marketcap_pct"
          isSortable={columnsSortableByClickingOnHeader}
          headerRenderer={HeaderCell}
          cellRenderer={row => PercentCellRenderer(row, null, null)}
        />
        <Column
            title="Change from all time high"
            width={100}
            dataKey="percent_from_price_ath"
            isSortable={columnsSortableByClickingOnHeader}
            headerRenderer={HeaderCell}
            cellRenderer={row => PercentCellRenderer(row, null, null)}
        />
        <Column
            title="All time high date"
            width={100}
            dataKey="ath_date"
            isSortable={columnsSortableByClickingOnHeader}
            headerRenderer={HeaderCell}
            cellRenderer={DateCellRenderer}
        />
        <Column
            title="ICO raised value"
            width={110}
            dataKey="raised_usd"
            isSortable={columnsSortableByClickingOnHeader}
            headerRenderer={HeaderCell}
            cellRenderer={NumberCellRenderer}
        />
        {!timesColumnLessPriority &&
        <Column
          title="Times to get back to ICO price"
          width={100}
          dataKey="times_to_get_back_to_ico_price"
          isSortable={columnsSortableByClickingOnHeader}
          headerRenderer={HeaderCell}
          cellRenderer={TimesCellRenderer}
        />}
        {timesColumnLessPriority &&
        <Column
          title="Change from ICO price"
          width={100}
          dataKey="pct_since_ico"
          isSortable={columnsSortableByClickingOnHeader}
          headerRenderer={HeaderCell}
          cellRenderer={row => PercentCellRenderer(row, null, null)}
        />}
        <Column
          title="Future inflation (times)"
          width={80}
          dataKey="future_inflation"
          isSortable={columnsSortableByClickingOnHeader}
          headerRenderer={HeaderCell}
          cellRenderer={TimesCellRenderer}
        />
        <Column
          title="Beta coef."
          width={80}
          dataKey="beta_value"
          isSortable={columnsSortableByClickingOnHeader}
          headerRenderer={HeaderCell}
          cellRenderer={RegularCellRenderer}
        />
        {timesColumnLessPriority &&
        <Column
          title="Times to get back to ICO price"
          width={100}
          dataKey="times_to_get_back_to_ico_price"
          isSortable={columnsSortableByClickingOnHeader}
          headerRenderer={HeaderCell}
          cellRenderer={TimesCellRenderer}
        />}
        {!timesColumnLessPriority &&
        <Column
          title="Change from ICO price"
          width={100}
          dataKey="pct_since_ico"
          isSortable={columnsSortableByClickingOnHeader}
          headerRenderer={HeaderCell}
          cellRenderer={row => PercentCellRenderer(row, null, null)}
        />}
        <Column
          title="Platform"
          width={90}
          dataKey="platform.name"
          isSortable={columnsSortableByClickingOnHeader}
          headerRenderer={HeaderCell}
        />
        <Column
          title="ICO end date"
          width={95}
          dataKey="ico_date"
          isSortable={columnsSortableByClickingOnHeader}
          headerRenderer={HeaderCell}
          cellRenderer={DateCellRenderer}
        />
        <Column
          title="Raised % of hard cap"
          width={80}
          dataKey="ico_received_percent"
          isSortable={columnsSortableByClickingOnHeader}
          headerRenderer={HeaderCell}
          cellRenderer={row => PercentCellRenderer(row, null, null)}
        />
        <Column
          title="ICO price"
          width={120}
          dataKey="ico_price"
          isSortable={columnsSortableByClickingOnHeader}
          headerRenderer={HeaderCell}
          cellRenderer={row => {
            if (row.rowData.ico_price) {
              return (
                <div>
                  ${row.rowData.ico_price}
                </div>
              );
            }
            return <div />;
          }}
        />
        <Column
          title="Current Price"
          width={110}
          dataKey="quote.USD.price"
          isSortable={columnsSortableByClickingOnHeader}
          headerRenderer={HeaderCell}
          cellRenderer={row => (
            <div>
              ${_.round(Number.parseFloat(row.rowData.quote.USD.price), 6)}
            </div>
          )}
        />
      </Table>
    </div>
  );
}

CryptoTable.propTypes = {
  rows: PropTypes.array.isRequired,
  handleLoadMoreRows: PropTypes.func.isRequired,
  totalRowsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  timesColumnLessPriority: PropTypes.bool,
  handleRowCheck: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired
};
