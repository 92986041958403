import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Tooltip } from 'antd';

export default function PercentCellRenderer(row, link, websiteType) {
  const { cellData, dataKey, rowData: { sortColumnName } } = row;
  let cellClassName = (dataKey === sortColumnName) ? 'SortingColumnCell' : null;
  if (
    dataKey === 'percent_change_7d' || dataKey === 'percent_change_24h' || dataKey === 'pct_since_ico' ||
    dataKey === 'percent_change_7d_adjusted' || dataKey === 'percent_change_24h_adjusted'
  ) {
    cellClassName = (cellData >= 0) ? `${cellClassName} PositiveChangeCell` : `${cellClassName} NegativeChangeCell`;
  }

  if (cellData != null && !_.isNaN(cellData)) {
    if (link) {
      const tooltipTitle = `Open ${row.rowData.name} (${row.rowData.symbol}) ${websiteType}`;
      return (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <Tooltip title={tooltipTitle}>
            <div className={cellClassName}>
              {cellData.toLocaleString()}%
            </div>
          </Tooltip>
        </a>
      );
    } else {
      return (
        <div className={cellClassName}>
          {cellData.toLocaleString()}%
        </div>
      );
    }
  }

  return <div />;
}

PercentCellRenderer.propTypes = {
  row: PropTypes.shape({
    cellData: PropTypes.number,
    dataKey: PropTypes.string.isRequired,
    rowData: PropTypes.shape({
      sortColumnName: PropTypes.string
    })
  })
};
