import React, { Component } from 'react';
import './Feedback.css';
import { Modal, Button, Input } from 'antd';
import AWS from 'aws-sdk';
import ReactGA from 'react-ga';
import { MessageOutlined } from '@ant-design/icons';
import { snsEndpoint, snsFeedbackArn } from 'constants/config';

const { TextArea } = Input;

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalText: 'Write something below:',
      visible: false,
      confirmLoading: false,
      feedbackMsg: ''
    };
  };

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.visible === prevState.visible && this.state.visible) {
      this.setState({
        modalText: 'Write something below:',
        feedbackMsg: ''
      });
    }
  }

  showModal = () => {
    ReactGA.pageview('/feedback-modal-open');
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    ReactGA.pageview('/feedback-send');
    const { feedbackMsg } = this.state;

    if (!feedbackMsg || feedbackMsg.length < 5) {
      this.setState({
        modalText: 'You have not written anything! Write something below (minimum 5 characters):'
      });
    } else {
      this.setState({
        modalText: 'Thank you for the feedback!',
        confirmLoading: true,
      });

      AWS.config.update({
        endpoint: snsEndpoint
      });
      const sns = new AWS.SNS({params: {TopicArn: snsFeedbackArn}});
      sns.publish({Message: feedbackMsg}, (err, data) => {
        if (!err) {
          ReactGA.pageview('/feedback-send-success');
        }
        setTimeout(() => {
          this.setState({
            visible: false,
            confirmLoading: false
          });
        }, 800);
      });
    }
  };

  handleFeedbackMsg = (e) => {
    this.setState({ feedbackMsg: e.target.value });
  };

  handleCancel = () => {
    ReactGA.pageview('/feedback-cancel');
    this.setState({
      visible: false,
    });
  };

  render() {
    const { visible, confirmLoading, modalText, feedbackMsg } = this.state;
    return (
      <div>
        <div className="FeedbackButtonContainer">
          <Button type="secondary" size="small" icon={<MessageOutlined />} block={true} onClick={this.showModal}>
            Leave feedback
          </Button>
        </div>
        <Modal
          title="What do you think about it?"
          visible={visible}
          onOk={this.handleOk}
          confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
        >
          <p>{modalText}</p>
          <TextArea rows={5} value={feedbackMsg} onChange={this.handleFeedbackMsg}/>
        </Modal>
      </div>
    );
  }
}

export default Feedback;
