import React from 'react';
import PropTypes from 'prop-types';

export default function RoundedNumberCellRenderer(row) {
  const { cellData, dataKey, rowData: { sortColumnName } } = row;
  const cellClassName = (dataKey === sortColumnName) ? 'SortingColumnCell' : null;

  if (cellData) {
    return (
      <div className={cellClassName}>
        ${Math.round(row.rowData.volume_24h).toLocaleString()}
      </div>
    );
  }

  return <div />;
}

RoundedNumberCellRenderer.propTypes = {
  row: PropTypes.shape({
    cellData: PropTypes.number,
    dataKey: PropTypes.string.isRequired,
    rowData: PropTypes.shape({
      sortColumnName: PropTypes.string
    })
  })
};
