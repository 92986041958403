import React from 'react';
import spinner from 'assets/spinner.gif';
import './Spinner.css';

export default function Spinner() {
  return (
    <div className="SpinnerBox">
      <img src={spinner} className="SpinnerImage" alt="spinner"/>
      Loading...
    </div>
  );
}
