import React from 'react';
import { Tooltip } from 'antd';

export default function LinkCellRenderer(row, link, websiteType) {
  const { dataKey, rowData: { sortColumnName } } = row;
  const cellClassName = (dataKey === sortColumnName) ? 'SortingColumnCell' : null;
  if (link) {
    const tooltipTitle = `Open ${row.rowData.name} (${row.rowData.symbol}) ${websiteType}`;
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        <Tooltip title={tooltipTitle}>
          <div className={`CellWithLink ${cellClassName}`}>
            {row.cellData}
          </div>
        </Tooltip>
      </a>
    )
  }

  return (
    <div className={cellClassName}>
      {row.cellData}
    </div>
  );
}

